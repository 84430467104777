import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Tab, Tabs, Typography } from "@mui/material";
import ClientStatementTable from "../../components/Members/ClientStatementTable";

const ClientStatement = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <ClientStatementTable />
      </Box>
    </Container>
  );
};

export default ClientStatement;
