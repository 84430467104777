import React, { useEffect, useState } from "react";
import axios from "../../helpers/axios";
import { Tab, Tabs, Button, Box } from "@mui/material";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import axiosInstance from "../../helpers/axios";
import { async } from "@babel/runtime/regenerator";
import Swal from "sweetalert2";

export default function CollectionReport() {
  const [activeTabSport, setActiveTabSport] = useState(0);
  const [lenaHai, setLenaHai] = useState([]);
  const [denaHai, setDenaHai] = useState([]);
  const [clearHai, setClearHai] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [selectedDW, setSelectedDW] = useState({});
  const [editingCreditLimit, setEditingCreditLimit] = useState({});
  const [creditLimitValues, setCreditLimitValues] = useState({});
  const [transactionValues, setTransactionValues] = useState({});
  const [remarkValues, setRemarkValues] = useState({});
  const [changedRecords, setChangedRecords] = useState([]);
  const handleDWClick = (id, type) => {
    setSelectedDW((prev) => ({
      ...prev,
      [id]: prev[id] === type ? null : type,
    }));
  };

  const handleEditCreditLimit = (id) => {
    setEditingCreditLimit((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const handleConfirmCreditLimit = async (id) => {
    try {
      setEditingCreditLimit((prev) => ({
        ...prev,
        [id]: false,
      }));
      const inputData = {
        _id: id,
        member_max_credit: creditLimitValues[id],
      };
      await axiosInstance.put("/users/update-user", inputData);
      getCollection();
    } catch (err) {}
  };

  const handleCancelCreditLimit = (id) => {
    setEditingCreditLimit((prev) => ({
      ...prev,
      [id]: false,
    }));
  };

  const handleCopyAmount = (amount, id) => {
    navigator.clipboard.writeText(amount);

    setTransactionValues((prevValues) => ({
      ...prevValues,
      [id]: amount,
    }));

    updateChangedRecords(id, { transactionValues: amount });
  };

  const handleTransactionChange = (e, id) => {
    const value = e.target.value;
    setTransactionValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    updateChangedRecords(id, { transactionValues: value });
  };

  const handleCreditLimitChange = (e, credId) => {
    const value = e.target.value;
    setCreditLimitValues((prevValues) => ({
      ...prevValues,
      [credId]: value,
    }));
  };

  const handleRemarkChange = (e, id) => {
    const value = e.target.value;
    setRemarkValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }));
    updateChangedRecords(id, { remark: value });
  };

  const updateChangedRecords = (id, updatedFields) => {
    setChangedRecords((prevRecords) => {
      const existingRecordIndex = prevRecords.findIndex(
        (record) => record.report_id === id
      );

      if (existingRecordIndex !== -1) {
        const updatedRecord = {
          ...prevRecords[existingRecordIndex],
          ...updatedFields,
        };
        const updatedRecords = [...prevRecords];
        updatedRecords[existingRecordIndex] = updatedRecord;
        return updatedRecords;
      } else {
        return [...prevRecords, { report_id: id, ...updatedFields }];
      }
    });
  };

  const handleSubmit = async (type) => {
    const formattedRecords = changedRecords.map((record) => ({
      ...record,
      transactionValues: String(record.transactionValues).startsWith("-")
        ? String(record.transactionValues).slice(1)
        : String(record.transactionValues),
    }));

    const result = {
      type: type,
      collectionReports: formattedRecords,
    };

    try {
      const res = await axios.post("/users/settleCollectionReport", {
        ...result,
      });
      if (res.data.status) {
        getCollection();
        setChangedRecords([]);
        setTransactionValues("");
        setRemarkValues("");
        Swal.fire("User Transaction Successful");
      }
    } catch (er) {
      console.log(er);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  
function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

  const columns = [
    {
      field: "id",
      headerName: "NO.",
      width: 50,
    },
    {
      field: "collect_from_username",
      headerName: "User Name",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params?.row?.collect_from_user
          ? params.row?.collect_from_user.username
          : "";

        return <span style={{textTransform:"capitalize"}} >{value}</span>;
      },
    },
    {
      field: "amount",
      headerName: "P & L",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params?.row?.amount ? params.row?.amount.toFixed(2) : "";
        const color = params.row?.amount < 0 ? "red" : "green";

        return <span style={{ color: color }}>{value}</span>;
      },
    },
    {
      field: "transactions",
      headerName: "Transactions",
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <input
            type="text"
            style={{
              width: "60%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            value={transactionValues[params.row._id] || ""}
            onChange={(e) => handleTransactionChange(e, params.row._id)}
          />
          <Button
            style={{
              backgroundColor: "black",
              color: "white",
              width: "30px",
              height: "30px",
            }}
            onClick={() => handleCopyAmount(params.row.amount, params.row._id)}
          >
            Full
          </Button>
        </div>
      ),
    },
    {
      field: "credit_limit",
      headerName: "Credit Limit",
      minWidth: 230,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isEditing =
          editingCreditLimit[params?.row?.collect_from_user?._id] || false;
        const value =
          creditLimitValues[params?.row?.collect_from_user?._id] ||
          params?.row?.collect_from_user?.member_max_credit;

        return (
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            {isEditing ? (
              <>
                <input
                  type="text"
                  value={value}
                  style={{
                    width: "100px",
                    padding: "8px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                  onChange={(e) =>
                    handleCreditLimitChange(
                      e,
                      params?.row?.collect_from_user?._id,
                      params?.row?._id
                    )
                  }
                />
                <button
                  style={{
                    padding: "6px 12px",
                    backgroundColor: "green",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleConfirmCreditLimit(
                      params?.row?.collect_from_user?._id
                    )
                  }
                >
                  ✔
                </button>
                <button
                  style={{
                    padding: "6px 12px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleCancelCreditLimit(params?.row?.collect_from_user?._id)
                  }
                >
                  ✖
                </button>
              </>
            ) : (
              <>
                <span>{value}</span>
                <button
                  style={{
                    padding: "4px",
                    backgroundColor: "#f0f0f0",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleEditCreditLimit(params?.row?.collect_from_user?._id)
                  }
                >
                  ✏️
                </button>
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="text"
          value={remarkValues[params.row._id] || ""}
          style={{
            width: "80%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
          onChange={(e) => handleRemarkChange(e, params.row._id)}
        />
      ),
    },
    {
      field: "credits",
      headerName: "Available Balance",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = params?.row?.collect_from_user?.credits
          ? params.row?.collect_from_user?.credits.toFixed(2)
          : "";

        return <span>{value}</span>;
      },
    },
  ];

  const getCollection = async () => {
    try {
      const res = await axios.get("/users/getCollectionReport", {
        params: { user_id: localStorage.getItem("user_id") },
      });
      if (res.data.status) {
        const data = res.data.dataobj;
        setAllCollections(data);
        const addIndex = (list) => {
          const modifyedList = list.map((value, index) => ({
            id: index + 1,
            ...value,
          }));
          return modifyedList;
        };
        setDenaHai(addIndex(data.filter((i) => i.amount > 0)));
        setLenaHai(addIndex(data.filter((i) => i.amount < 0)));
        setClearHai(data.filter((i) => i.amount === 0));
      }
    } catch (er) {
      console.log(er);
    }
  };
  useEffect(() => {
    getCollection();
  }, []);



  const returnCurrentTabTable = () => {
    const generateRowId = (row, index) => row._id || `${row.user_id}-${index}`;

    if (activeTabSport === 0)
      return (
        <div style={{ height: "auto", width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            PAYMENT RECEIVING FROM (Lena hai)
          </div>
          <div
            style={{ height: "auto", width: "100%", paddingBottom: "120px" }}
          >
            <Box
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "black !important", // Header background color
                  color: "#fff", // Header text color
                },
                "& .css-163fvz6-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                  {
                    backgroundColor: "black", // Set the background color for rows in the top container
                  },
                "& .css-163fvz6-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                  {
                    backgroundColor: "black", // Set the background color for rows in the bottom container
                  },
                "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                  color: "#fff", // Sort icon color when not sorted
                },
                "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                  color: "#fff", // Sort icon color when sorted
                },
                "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                  {
                    color: "#fff", // Sort icon color when sorted in descending order
                  },
                "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                  {
                    color: "#fff", // Sort icon color when sorted in ascending order
                  },
                "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                  {
                    backgroundColor: "black", // Top container row background color
                  },
                "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                  {
                    backgroundColor: "blue", // Bottom container row background color
                  },
                "& .MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "#fafafa", // Odd row background
                },
                "& .MuiDataGrid-row:nth-of-type(even)": {
                  backgroundColor: "#fff", // Even row background
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#e0f7fa", // Row hover color
                },
                "& .MuiDataGrid-cell": {
                  color: "#000", // Cell text color
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "#f5f5f5", // Footer background
                },
              }}
            >
              <DataGrid
   rowHeight={40} 
                localeText={{
                  noRowsLabel: "No data available.", // Custom "No rows" message
                }}
                rows={lenaHai}
                columns={columns}
                getRowId={(row, index) => generateRowId(row, index)}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                autoHeight
                disableSelectionOnClick
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: "16px",
                backgroundColor: "#000",
                color: "white",
                float: "right",
              }}
              onClick={() => handleSubmit("lena")}
            >
              Submit
            </Button>
          </div>
        </div>
      );
    else if (activeTabSport === 1)
      return (
        <div style={{ height: "auto", width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            PAYMENT PAID TO (Dena hai)
          </div>
          <div
            style={{ height: "auto", width: "100%", paddingBottom: "120px" }}
          >
            <Box
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "black !important", // Header background color
                  color: "#fff", // Header text color
                },
                "& .css-163fvz6-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                  {
                    backgroundColor: "black", // Set the background color for rows in the top container
                  },
                "& .css-163fvz6-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                  {
                    backgroundColor: "black", // Set the background color for rows in the bottom container
                  },
                "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                  color: "#fff", // Sort icon color when not sorted
                },
                "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                  color: "#fff", // Sort icon color when sorted
                },
                "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                  {
                    color: "#fff", // Sort icon color when sorted in descending order
                  },
                "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                  {
                    color: "#fff", // Sort icon color when sorted in ascending order
                  },
                "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                  {
                    backgroundColor: "black", // Top container row background color
                  },
                "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                  {
                    backgroundColor: "blue", // Bottom container row background color
                  },
                "& .MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "#fafafa", // Odd row background
                },
                "& .MuiDataGrid-row:nth-of-type(even)": {
                  backgroundColor: "#fff", // Even row background
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#e0f7fa", // Row hover color
                },
                "& .MuiDataGrid-cell": {
                  color: "#000", // Cell text color
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "#f5f5f5", // Footer background
                },
              }}
            >
              <DataGrid
   rowHeight={40} 
                localeText={{
                  noRowsLabel: "No data available.", // Custom "No rows" message
                }}
                rows={denaHai}
                columns={columns}
                getRowId={(row, index) => generateRowId(row, index)}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                autoHeight
                disableSelectionOnClick
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              style={{
                marginTop: "16px",
                backgroundColor: "#000",
                color: "white",
                float: "right",
              }}
              onClick={() => handleSubmit("dena")}
            >
              Submit
            </Button>
          </div>
        </div>
      );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTabSport(newValue);
    setChangedRecords([]);
    setTransactionValues("");
    setRemarkValues("");
  };

  return (
    <div>
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          placeItems: "center",
          background: "",
          borderRadius: "8px",
          marginBottom: "8px",
        }}
      >
        <Tabs
          value={activeTabSport}
          sx={{
            ".Mui-selected": {
              color: `#fff`,
              outline: "none",
            },
            "&:hover": {
              outline: "none",
            },
          }}
          TabIndicatorProps={{ style: { background: "#b025a8" } }}
          aria-label="tabs example"
          onChange={handleTabChange}
        >
          <Tab
            value={0}
            label={
              <span
                style={{ color: activeTabSport == 0 ? "#b025a8" : "#808080" }}
              >
                Lena hai
              </span>
            }
          />
          <Tab
            value={1}
            label={
              <span
                style={{ color: activeTabSport == 1 ? "#b025a8" : "#808080" }}
              >
                Dena hai
              </span>
            }
          />
        </Tabs>
      </div>
      <div>
        <div id="content-id">{returnCurrentTabTable()}</div>
      </div>
    </div>
  );
}
