import axiosInstance from "../helpers/axios";

class AuthService {
  logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("email");
    localStorage.removeItem("username");
    localStorage.removeItem("permissions");
    localStorage.clear();
  };

  register = (username, email, password, active) =>
    axiosInstance.post(`users/signup`, {
      username,
      email,
      password,
      active,
    });

  getCurrentUser = () => JSON.parse(localStorage.getItem("user"));
}

export default new AuthService();
