import axios from "axios";
import authService from "../services/auth.service";
import history from "./history";

// const baseURL = "http://localhost:8800/api"
const baseURL = "https://api.softbet9.com/api"
  // process.env.REACT_APP_API_ENDPOINT;

let headers = {};

if (localStorage.getItem("token")) {
  headers = { Authorization: `Bearer ${localStorage.getItem("token")}` };
}

const axiosInstance = axios.create({ baseURL, headers });

axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      authService.logout();
      history.push("/login", { replace: true });
      window.location.reload();
    } else if (error?.response?.status === 403) {
      authService.logout();
      history.push("/login", { replace: true });
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
