import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import axios from "../../helpers/axios";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";

function MyStatementTable() {
  const [data, setData] = useState();
  const [value, onChange] = useState({
    startDate: new Date().toISOString().split("T")[0], // 'YYYY-MM-DD'
    endDate: new Date().toISOString().split("T")[0],
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const columns = [
    { field: "date", headerName: "Date", width: 120 },
    {
      field: "description",
      headerName: "Description",
      width: isMobile ? 150 : 200,
    },
    { field: "credit", headerName: "Cr", width: isMobile ? 90 : 100 },
    { field: "debit", headerName: "Dbt", width: 100 },
    { field: "remark", headerName: "Remark", width: 120 },
    { field: "balance", headerName: "Balance", width: 150 },
  ];

  // const data = [
  //   {
  //     id: "2023-01-01",
  //     pid: "Transaction1",
  //     title: 0,
  //     sports: 50,
  //     date: 0,
  //     profit_loss: "Debit",
  //     balance: 50,
  //   },
  //   {
  //     id: "2023-01-02",
  //     pid: "Transaction2",
  //     title: 30,
  //     sports: 0,
  //     date: 0,
  //     profit_loss: "Credit",
  //     balance: 20,
  //   },
  //   {
  //     id: "2023-01-03",
  //     pid: "Transaction3",
  //     title: 0,
  //     sports: 20,
  //     date: 0,
  //     profit_loss: "Debit",
  //     balance: 40,
  //   },
  //   {
  //     id: "2023-01-04",
  //     pid: "Transaction4",
  //     title: 15,
  //     sports: 0,
  //     date: 0,
  //     profit_loss: "Credit",
  //     balance: 25,
  //   },
  //   // Add more data rows as needed
  // ];
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem   />
          {/* <JsonExportMenuItem /> */}
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  }
  useEffect(() => {
    const getStatements = async () => {
      try {
        const res = await axios.get("/users/getUserStatement", {
          params: {
            user_id: localStorage.getItem("user_id"),
            startDate: value.startDate,
            endDate: value.endDate,
          },
        });
        if (res.data.status) {
          setData(res.data.dataobj);
        }
      } catch (er) {
        console.log(er);
      }
    };
    if (value.startDate && value.endDate) {
      getStatements();
    }
  }, [value]);

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={value.startDate}
            onChange={(e) => onChange({ ...value, startDate: e.target.value })}
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={value.endDate}
            onChange={(e) => onChange({ ...value, endDate: e.target.value })}
            fullWidth
            size="small"
          />
        </Grid>
      </Grid>
      <div
        style={{
          height: "auto",
          width: "100%",
          marginTop: "16px",
          paddingBottom: "100px",
        }}
      >
        <Box
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black !important", // Header background color
              color: "#fff", // Header text color
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when not sorted
            },
            "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when sorted
            },
            "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in descending order
              },
            "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in ascending order
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
              {
                backgroundColor: "black", // Top container row background color
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
              {
                backgroundColor: "blue", // Bottom container row background color
              },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#fafafa", // Odd row background
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#fff", // Even row background
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#e0f7fa", // Row hover color
            },
            "& .MuiDataGrid-cell": {
              color: "#000", // Cell text color
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#f5f5f5", // Footer background
            },
          }}
        >
          <DataGrid
   rowHeight={40} 
localeText={{
              noRowsLabel: "No data available.",  // Custom "No rows" message
            }}
            rows={data}
            getRowId={(row) => row._id}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{
              toolbar: CustomToolbar,
            }}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      </div>
    </div>
  );
}

export default MyStatementTable;
