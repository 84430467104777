import React, { useState, useEffect } from "react";
import { Switch, Grid, Typography, Box, Button } from "@mui/material";
import axiosInstance from "../../../helpers/axios";

const MasterUserSettings = ({
  onClose,
  getGames,
  userSettings,
  userId,
  setSnackbarOpen,
  setSnackbarSeverity,
  setSnackbarMessage,
}) => {
  const [eventStatus, setEventStatus] = useState({});
  const [updateData, setUpdateData] = useState({});

  const setEvent = () => {
    const initialEventStatus = getGames.reduce((acc, game) => {
      const matchedSetting = userSettings?.find(
        (setting) => setting.sportId === game._id
      );
      return {
        ...acc,
        [game.gName.toLowerCase()]: matchedSetting
          ? matchedSetting.isBlocked
          : false,
      };
    }, {});

    setEventStatus(initialEventStatus);
  };

  useEffect(() => {
    setEvent();
  }, [getGames, userSettings?.blocked_sports]);

  const handleToggle = (sportId) => {
    const updatedBlockedSports = getGames.map((game) => {
      if (game.gName.toLowerCase() === sportId) {
        return {
          sportId: game._id,
          isBlocked: !eventStatus[game.gName.toLowerCase()],
        };
      }
      return {
        sportId: game._id,
        isBlocked: eventStatus[game.gName.toLowerCase()],
      };
    });

    const updatedEventStatus = {
      ...eventStatus,
      [sportId]: !eventStatus[sportId],
    };

    setUpdateData({
      _id: userId,
      blocked_sports: updatedBlockedSports,
    });

    setEventStatus(updatedEventStatus);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .put("/users/update-user", updateData)
      .then((response) => {
        setSnackbarMessage("User Updated Successfully!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        onClose();
      })
      .catch((err) => {
        setSnackbarMessage("Something went wrong!");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        onClose();
      });
  };

  return (
    <Box component="form">
      <Typography variant="h6" sx={{ mb: 3 }}>
        Master User Settings
      </Typography>

      {/* Event Table */}
      <Grid container spacing={2}>
        {/* Table Header */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Event
          </Typography>
        </Grid>

        {/* Cricket Row */}
        <Grid item xs={6}>
          <Typography>Cricket</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            checked={eventStatus.cricket}
            onChange={() => handleToggle("cricket")}
            color="primary"
          />
        </Grid>

        {/* Tennis Row */}
        <Grid item xs={6}>
          <Typography>Tennis</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            checked={eventStatus.tennis}
            onChange={() => handleToggle("tennis")}
            color="primary"
          />
        </Grid>

        {/* Soccer Row */}
        <Grid item xs={6}>
          <Typography>Soccer</Typography>
        </Grid>
        <Grid item xs={6}>
          <Switch
            checked={eventStatus.soccer}
            onChange={() => handleToggle("soccer")}
            color="primary"
          />
        </Grid>
      </Grid>

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="outlined" sx={{ mr: 2 }} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#735CFF",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5c49cc",
            },
          }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default MasterUserSettings;
