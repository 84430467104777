import { Suspense } from 'react';
import './App.css';
import Router from './routes/routes';
import PageLoader from './components/PageLoader';

function App() {
  return (
    <Suspense fallback={<PageLoader/>} >
    <Router />
    </Suspense>
  );
}

export default App;
