import React, { useEffect, useState } from "react";
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import {
  Alert,
  Box,
  Button,
  Modal,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
  Tooltip,
  IconButton,
  Chip,
} from "@mui/material";
import NewManageUserForm from "../partials/NewManageUserForm";
import axiosInstance from "../../../helpers/axios";
import Swal from "sweetalert2";
import MasterUserSettings from "../../Members/MasterUserSettings/MasterUserSettings";
import MasterUserLimit from "../../Members/MasterUserLimit/MasterUserLimit";
import sha256 from "sha256";
import * as yup from "yup";
import DwcForm from "../../Members/DWCForm/DwcForm";
import { useNavigate } from "react-router-dom";
import BetLoackPopUP from "../../BetLoackPopUP/BetLoackPopUP";

export default function ManageAdminTable() {
  const navigate = useNavigate();
  const [getGames, setGetGames] = useState();
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem   />
          {/* <JsonExportMenuItem /> */}
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  }
  const columns = [
    {
      field: "id",
      headerName: "NO.",
      width: 50,
    },
    {
      field: "username",
      headerName: "User Id",
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => {
        const userTypeSymbol = {
          super_master: "SM",
          master: "M",
          user: "U",
          admin: "A",
          super_admin: "SA",
        };

        const symbol = userTypeSymbol[params.row.user_type] || "";

        const handleClick = () => {
          navigate(`/members/super-master/${params.row._id}`);
        };

        return (
          <>
            {/* <span
              style={{
                backgroundColor: "#18AEC5",
                color: "white",
                borderRadius: "12px",
                padding: "2px 8px",
                marginRight: "5px",
                display: "inline-block",
                height: "23px",
                lineHeight: "23px",
              }}
            >
              {symbol}
            </span> */}
            <span
              onClick={handleClick}
              style={{
                color: "blue",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {params.value.toUpperCase()}
            </span>
          </>
        );
      },
    },
    {
      field: "first_name",
      headerName: "Name",
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                textTransform: "capitalize",
              }}
            >
              {params.value}
            </span>
          </>
        );
      },
    },
    {
      field: "member_max_credit",
      headerName: "Credit Reference",
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "credits",
      headerName: "Balance",
      width: 130,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ color: params.value >= 0 ? "green" : "red" }}>
          {params.value.toFixed(2)}
        </span>
      ),
    },
    // {
    //   field: "fix_limit",
    //   headerName: "Fix Limit",
    //   type: "number",
    //   width: 90,
    //   disableColumnMenu: true,
    // },
    {
      field: "my_match_share",
      headerName: "My Share",
      description: "This column has a value getter and is not sortable.",
      width: 160,
      disableColumnMenu: true,
      valueGetter: (value, row) => `${row?.my_match_share || ""}`,
    },

    {
      field: "exposure",
      headerName: "Exposure",
      width: 160,
      disableColumnMenu: true,
      valueGetter: (value, row) => `${row?.exposure || "0"}`,
    },
    {
      field: "profitLoss",
      headerName: "Profit/Loss",
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        const value = parseFloat(params.row?.profitLoss || "0");
        const color = value >= 0 ? "green" : "red";

        return (
          <span style={{ color: color, fontWeight: "bold" }}>
            {value.toFixed(2)}
          </span>
        );
      },
    },
    {
      field: "agent_block",
      headerName: "Lock",
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => (
        <BetLoackPopUP parameter={params} confirmDialog={confirmDialog} />

      ),
    },
    // {
    //   field: "agent_block",
    //   headerName: "User Lock",
    //   width: 100,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <input
    //       type="checkbox"
    //       checked={params.row.agent_block}
    //       onChange={(e) =>
    //         params.row.handleCheckboxClick(e, params.row._id, "agent_block")
    //       }
    //     />
    //   ),
    // },
    // {
    //   field: "bets_block",
    //   headerName: "Bet Lock",
    //   width: 100,
    //   disableColumnMenu: true,
    //   renderCell: (params) => (
    //     <input
    //       type="checkbox"
    //       checked={params.row.bets_block}
    //       onChange={(e) =>
    //         params.row.handleCheckboxClick(e, params.row._id, "bets_block")
    //       }
    //     />
    //   ),
    // },
    {
      field: "dwc",
      headerName: "D-W-C",
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Deposit">
            <IconButton
              variant="text"
              onClick={() => handleDwcClick(0, params.row)}
            >
              <Chip  label="D" />
            </IconButton>
            </Tooltip>
            <Tooltip title="Withdraw">
            <IconButton
              variant="text"
              onClick={() => handleDwcClick(1, params.row)}
            >
              <Chip  label="W" />
            </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span>
          <span
            style={{ margin: "0 5px", cursor: "pointer" }}
            onClick={() => params.row.handlePasswordChangeClick(params.row._id)}
          >
            🅿️
          </span>
          <span
            style={{ margin: "0 5px", cursor: "pointer" }}
            onClick={() => {
              const userInfo = filteredRows.filter(
                (limit) => limit._id === params.row._id
              );
              params.row.handleLimitClick(userInfo);
            }}
          >
            📋
          </span>
          <span
            style={{ margin: "0 5px", cursor: "pointer" }}
            onClick={() => params.row.handleSettingsClick(params.row)}
          >
            ⚙️
          </span>
        </span>
      ),
    },
  ];

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [errors, setErrors] = useState({});
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    rowId: null,
    field: "",
  });

  const [activeTab, setActiveTab] = useState();

  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openDwcModal, setOpenDwcModal] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const userType = localStorage.getItem("user_type");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    handleFetchRows();
  };

  const handleDwcClick = (tab, row) => {
    setCurrentRow(row);
    setActiveTab(tab);
    setOpenDwcModal(true);
  };

  const handleDwcModalClose = () => {
    setOpenDwcModal(false);
    handleFetchRows();
  };

  const handleFetchRows = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userProfile"));
      const response = await axiosInstance.get(
        `/users/get-users?user_type=admin&user_id=${userDetails?._id}`
      );
      const data = response.data;
      setRows(data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleReload = () => {
    handleFetchRows();
  };

  useEffect(() => {
    handleFetchRows();
  }, []);

  const [passwordModal, setPasswordModal] = useState({
    open: false,
    rowId: null,
  });
  const [limitModal, setLimitModal] = useState({
    open: false,
    info: null,
  });
  const [settingsModal, setSettingsModal] = useState({
    open: false,
    rowId: null,
  });

  const handlePasswordChangeClick = (rowId) => {
    setPasswordModal({ open: true, rowId });
  };
  const handleLimitClick = (info) => {
    setLimitModal({ open: true, info });
  };
  const handleSettingsClick = async (userData) => {
    try {
      const result = await axiosInstance.get("/game/getGames", {});
      setGetGames(result?.data?.dataobj);
    } catch (err) {
      console.log(err);
    }
    setSettingsModal({ open: true, userData });
  };

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handlePasswordModalClose = () => {
    setPasswordModal({ open: false, rowId: null });
    setNewPassword("");
    setConfirmPassword("");
    handleFetchRows();
  };
  const handleLimitModalClose = () => {
    setLimitModal({ open: false, rowId: null });
    handleFetchRows();
  };
  const handleSettingsModalClose = () => {
    setSettingsModal({ open: false, rowId: null });
    handleFetchRows();
  };

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleCheckboxClick = (event, rowId, field) => {
    event.preventDefault();
    setConfirmDialog({ open: true, rowId, field });
  };

  const handleConfirm = (confirmed) => {
    if (confirmed) {
      const updatedRow = rows?.find((row) => row?._id === confirmDialog?.rowId);

      if (!updatedRow) {
        console.error("Row not found:", confirmDialog?.rowId);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Unable to find the row to update.",
        });
        setConfirmDialog({ open: false, rowId: null, field: "" });
        return;
      }

      const newValue = !updatedRow[confirmDialog?.field];

      setRows(
        rows.map((row) =>
          row._id === confirmDialog.rowId
            ? { ...row, [confirmDialog.field]: newValue }
            : row
        )
      );

      const updateData = {};
      if (confirmDialog.field === "agent_block") {
        updateData.agent_block = newValue;
      } else if (confirmDialog.field === "bets_block") {
        updateData.bets_block = newValue;
      }

      updateData._id = confirmDialog?.rowId;

      if (Object.keys(updateData).length > 1) {
        axiosInstance
          .put("/users/update-user", updateData)
          .then((response) => {
            Swal.fire("User updated successfully");
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      } else {
        console.warn("No fields to update");
      }
    }

    setConfirmDialog({ open: false, rowId: null, field: "" });
  };

  const passwordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handlePasswordSubmit = async () => {
    const formData = { newPassword, confirmPassword };

    try {
      await passwordSchema.validate(formData, { abortEarly: false });
      const encryptedPassword = sha256(newPassword).toString();
      const row = rows.find((row) => row._id === passwordModal.rowId);

      if (newPassword === confirmPassword) {
        axiosInstance
          .put("/users/change-password", {
            password: sha256(newPassword),
            username: row.username,
          })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              Swal.fire("Password Change Successfully");
            } else {
              throw new Error(`Unexpected response status: ${response.status}`);
            }
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password not matched.",
        });
      }
      handlePasswordModalClose();
    } catch (validationErrors) {
      const formErrors = {};
      validationErrors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setErrors(formErrors);
    }
  };

  return (
    <Box>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="flex-end"
        alignItems={isMobile ? "stretch" : "center"}
        spacing={isMobile ? 2 : 0}
        mb={2}
        gap={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          fullWidth={isMobile}
          size="small"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          sx={{
            backgroundColor: "#000",
            color: "white",
            height: "32px",
            width: isMobile ? "100%" : "auto",
            "&:hover": {
              backgroundColor: "#1598ac",
            },
          }}
        >
          Add Admin
        </Button>
      </Stack>

      <div style={{ height: 400, width: "100%", paddingBottom: "100px" }}>
        <Box
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black !important", // Header background color
              color: "#fff", // Header text color
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when not sorted
            },
            "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when sorted
            },
            "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in descending order
              },
            "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in ascending order
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
              {
                backgroundColor: "black", // Top container row background color
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
              {
                backgroundColor: "blue", // Bottom container row background color
              },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#fafafa", // Odd row background
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#fff", // Even row background
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#e0f7fa", // Row hover color
            },
            "& .MuiDataGrid-cell": {
              color: "#000", // Cell text color
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#f5f5f5", // Footer background
            },
          }}
        >
          <DataGrid
   rowHeight={40} 
            localeText={{
              noRowsLabel: "No data available.", // Custom "No rows" message
            }}
            rows={filteredRows.map((row,index) => ({
              id:index+1,
              ...row,
              handleCheckboxClick,
              handlePasswordChangeClick,
              handleLimitClick,
              handleSettingsClick,
            }))}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
        <Modal open={open} onClose={handleClose}>
          <div
            style={{
              padding: 20,
              background: "white",
              margin: "50px auto",
              maxWidth: 800,
              borderRadius: "15px",
            }}
          >
            <NewManageUserForm
              onClose={handleClose}
              userType="admin"
              handleReload={handleReload}
            />
          </div>
        </Modal>
      </div>
      <Modal open={openDwcModal} onClose={handleDwcModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 800,
            borderRadius: "15px",
          }}
        >
          <DwcForm
            onClose={handleDwcModalClose}
            userDetails={currentRow}
            activeTabValue={activeTab}
            handleReload={handleFetchRows}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        </Box>
      </Modal>

      <Modal open={passwordModal.open} onClose={handlePasswordModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 400,
            borderRadius: "15px",
          }}
        >
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <TextField
            size="small"
              label="New Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="dense"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={!!errors.newPassword}
              helperText={errors.newPassword}
            />
            <br/>
            <TextField
            size="small"
              label="Confirm Password"
              variant="outlined"
              type="password"
              fullWidth
              margin="dense"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePasswordModalClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handlePasswordSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Box>
      </Modal>

      <Modal open={limitModal.open} onClose={handleLimitModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 550,
            borderRadius: "15px",
          }}
        >
          <MasterUserLimit
            onClose={handleLimitModalClose}
            userType={"User"}
            info={limitModal.info}
          />
        </Box>
      </Modal>

      <Modal open={settingsModal.open} onClose={handleSettingsModalClose}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: "white",
            margin: "50px auto",
            maxWidth: isMobile ? "90%" : 400,
            borderRadius: "15px",
          }}
        >
          <MasterUserSettings
            onClose={handleSettingsModalClose}
            getGames={getGames}
            userSettings={settingsModal.userData?.blocked_sports}
            userId={settingsModal?.userData?._id}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
            userType="user"
          />
        </Box>
      </Modal>

      <Dialog open={confirmDialog.open} onClose={() => handleConfirm(false)}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleConfirm(true)} color="primary">
            Yes
          </Button>
          <Button onClick={() => handleConfirm(false)} color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
