import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Snackbar,
  Alert,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import axios from "../../helpers/axios";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

const BlockSport = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [colData, setColData] = useState([]);
  const [game, setData] = useState([]);
  const [btnClickFlag, setBtnClickFlag] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get("/game/getGames", {});
        setData(result?.data?.dataobj);
        setColData(result?.data?.data?.dataobj);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [btnClickFlag]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <TextField
        variant="outlined"
        placeholder="Search Here"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        size="small"
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    // { field: "id", headerName: "ID", flex: 1 },
    // Uncomment this line if you need to display "BetfairId"
    { field: "gId", headerName: "BetfairId", flex: 1 },
    {
      field: "gName",
      headerName: "Name",
      flex: 1,
      // renderCell: (params) => (
      //   <Button
      //     variant="text"
      //     onClick={() => navigate(`/block-sports/seriesList`)}
      //   >
      //     {params.row.gName}
      //   </Button>
      // ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.block}
          onChange={() =>
            handleCheckboxChange(params.row._id, !params.row.block)
          }
          color={params.row.block ? "secondary" : "primary"}
        />
      ),
    },
  ];

  const gameWithId = game.map((row, index) => ({ id: index, ...row }));

  const handleCheckboxChange = async (id, newBlockStatus) => {
    try {
      const response = await axios.post("/game/updateStatus", {
        _id: id,
        status: newBlockStatus,
      });
      if (response.data.status) {
        setSnackbarMessage(response.data.msg);
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      }

      setBtnClickFlag(!btnClickFlag);
      setData((prevData) =>
        prevData.map((row) =>
          row.id === id ? { ...row, block: newBlockStatus } : row
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Games
        </Typography>
        <Box sx={{ mb: 2 }}>{subHeaderComponentMemo}</Box>
        <Box sx={{ height: 600, width: "100%" }}>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black !important", // Header background color
                color: "#fff", // Header text color
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when not sorted
              },
              "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when sorted
              },
              "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in descending order
                },
              "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in ascending order
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Top container row background color
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                {
                  backgroundColor: "blue", // Bottom container row background color
                },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#fafafa", // Odd row background
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff", // Even row background
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e0f7fa", // Row hover color
              },
              "& .MuiDataGrid-cell": {
                color: "#000", // Cell text color
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f5f5f5", // Footer background
              },
            }}
          >
            <DataGrid
   rowHeight={40} 
localeText={{
              noRowsLabel: "No data available.",  // Custom "No rows" message
            }}
              rows={gameWithId}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              pagination
              autoHeight
              disableSelectionOnClick
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default BlockSport;
