import React, {useEffect, useState} from 'react';
import {Box, Container, Stack, Tab, Tabs, Typography} from '@mui/material';
import SuperMasterTable from '../../components/Members/SuperMasterTable';

const SuperMaster = () => {
  return (
    <Container maxWidth="xl" sx={{marginTop: '10px'}}>
      <Box sx={{width: '100%'}}>
        <SuperMasterTable />
      </Box>
    </Container>
  );
};

export default SuperMaster;
