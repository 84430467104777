import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import * as yup from "yup";
import {
  Box,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
  Grid,
  Snackbar,
  Alert,
  Autocomplete,
} from "@mui/material";
import axios from "../../helpers/axios";
import { useParams } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import { GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

export default function ClientStatementTable() {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [error, setError] = useState(false);
  const [value, onValueChange] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

  const columns = [
    {
      field: "id",
      headerName: "NO.",
      width: 50,
    },
    {
      field: "user_id",
      headerName: "User Id",
      minWidth: 130,
      disableColumnMenu: true,
    },

    {
      field: "date",
      headerName: "Date",
      minWidth: 130,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 130,
      disableColumnMenu: true,
    },
    {
      field: "credit",
      headerName: "Credit",
      minWidth: 130,
      disableColumnMenu: true,
    },
    {
      field: "debit",
      headerName: "Debit",
      type: "number",
      minWidth: 90,
      disableColumnMenu: true,
    },
    {
      field: "balance",
      headerName: "Balance",
      description: "This column has a value getter and is not sortable.",
      minWidth: 160,
      disableColumnMenu: true,
    },
  ];

  const [rows, setRows] = useState([]);
  const [clientRows, setClientRows] = useState([]);
  const [clients, setClients] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchClientTerm, setSearchClientTerm] = useState("");

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClient = (event) => {
    setSearchClientTerm(event);
  };

  const fetchClients = async () => {
    try {
      const res = await axiosInstance.get(
        `/users/client-list/${localStorage.getItem("user_id")}`
      );
      setClients(res.data.list);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchClients();
  }, []);
  const handleFetchUser = async () => {
    try {
      const response = await axios.get(
        `/users/get-user?username=${searchClientTerm}`
      );
      if (response.data.user == null && searchClientTerm !== "") {
        setError(true);
      } else {
        setError(false);
      }
      setClientRows(response.data.user);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const getStatements = async () => {
      try {
        const res = await axios.get("/users/getUserStatement", {
          params: {
            user_id: clientRows?._id,
            startDate: value.startDate,
            endDate: value.endDate,
          },
        });
        if (res.data.status) {
          setRows(res.data.dataobj);
        }
      } catch (er) {
        console.log(er);
      }
    };
    if (value.startDate && value.endDate) {
      getStatements();
    }
  }, [value, searchClientTerm, clientRows?._id]);

  const filteredRows = rows?.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    handleFetchUser();
  }, [searchClientTerm]);

  return (
    <Box>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobile ? "flex-start" : "center"}
        spacing={isMobile ? 2 : 0}
        mb={2}
      >
        {/* <TextField
          label="Search Client"
          variant="outlined"
          value={searchClientTerm}
          onChange={handleSearchClient}
          size="small"
          fullWidth={isMobile}
        /> */}
        <Autocomplete
          value={searchClientTerm}
          onChange={(event, newInputValue) => {
            handleSearchClient(newInputValue);
          }}
          id="controllable-states-demo"
          options={clients}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              label="Search Client"
            />
          )}
        />

        <Grid
          container
          rowSpacing={2}
          alignItems=""
          pl={isMobile ? "" : "15px"}
        >
          <Grid item xs={12} sm={3}>
            <TextField
              label="Start Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value.startDate}
              onChange={(e) =>
                onValueChange({ ...value, startDate: e.target.value })
              }
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={3} pl={isMobile ? "" : "15px"}>
            <TextField
              label="End Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={value.endDate}
              onChange={(e) =>
                onValueChange({ ...value, endDate: e.target.value })
              }
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          fullWidth={isMobile}
        />
      </Stack>
      {error && (
        <p style={{ color: "red", fontSize: "12px", marginTop: "4px" }}>
          No User {searchClientTerm} found
        </p>
      )}
      <div style={{ height: 400, width: "100%", paddingBottom: "100px" }}>
        <Box
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black !important", // Header background color
              color: "#fff", // Header text color
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when not sorted
            },
            "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when sorted
            },
            "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in descending order
              },
            "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in ascending order
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
              {
                backgroundColor: "black", // Top container row background color
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
              {
                backgroundColor: "blue", // Bottom container row background color
              },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#fafafa", // Odd row background
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#fff", // Even row background
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#e0f7fa", // Row hover color
            },
            "& .MuiDataGrid-cell": {
              color: "#000", // Cell text color
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#f5f5f5", // Footer background
            },
          }}
        >
          <DataGrid
   rowHeight={40} 
            localeText={{
              noRowsLabel: "No data available.", // Custom "No rows" message
            }}
            rows={filteredRows?.map((row,index) => ({
              id: index + 1,
              ...row,
            }))}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            autoHeight
            pageSizeOptions={[25, 50, 100]}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
