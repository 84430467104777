import React, { useState, useEffect } from "react";
import axios from "../../helpers/axios";
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Box, Tab, Tabs } from "@mui/material";


function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
export default function CompletedMatches() {
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabSport, setActiveTabSport] = useState(0);
  const [value, onChange] = useState([new Date(), new Date()]);
  const [matches, setMatches] = useState([]);
  const [sports_id, setSports_id] = useState(4);
  const [profitAndLoss, setProfitAndLoss] = useState({});
  const navigate = useNavigate();

  const getCompletedMatchs = async (sports_id) => {
    setMatches([]);
    try {
      let res = await axios.get("/matches/getCompletedMatches/" + sports_id);
      if (res.data.status) {
        setMatches(res.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getProfitAndLossOfCompletedMatchs = async () => {
    try {
      let res = await axios.get("/users/getProfitAndLossOfCompletedMatchs", {
        params: {
          user_id: localStorage.getItem("user_id"),
        },
      });
      if (res.data.status) {
        setProfitAndLoss(res.data.profit_loss_ledger);
      }
    } catch (err) {
      console.log(err);
      setProfitAndLoss({});
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCompletedMatchs(sports_id);
    };
    getProfitAndLossOfCompletedMatchs();
    fetchData();
  }, [activeTab, sports_id]);

  const columns = [
    {
      field: "match_id",
      headerName: "ID",
      flex: 1,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "match_name",
      headerName: "Title",
      flex: 2,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link
          to={`/live-matches/reports/${params.row.matchObj.id}`}
          style={{
            color: "#0080FF",
            display: "block",
            width: "100%",
          }}
        >
          {params.row.match_name}
        </Link>
      ),
      wrap: true,
    },
    {
      field: "start_time",
      headerName: "Date",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span style={{ width: "100%", textAlign: "center" }}>
          {formatDateAndTime(params.value)}
        </span>
      ),
    },
    {
      field: "declare",
      headerName: "Declare",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.row.matchObj.status === "declared" ? "No" : "Yes",
    },
    {
      field: "winner",
      headerName: "Won By",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "profit_loss",
      headerName: "Profit / Loss",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) =>
        params.value ? params.value : "Report Not Available",
    },
  ];

  function formatDateAndTime(dateString) {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const returnCurrentTabTable = () => {
    if (activeTab == 1 && activeTabSport == 0)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Completed Cricket Matches
          </div>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black !important", // Header background color
                color: "#fff", // Header text color
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when not sorted
              },
              "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when sorted
              },
              "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in descending order
                },
              "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in ascending order
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Top container row background color
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                {
                  backgroundColor: "blue", // Bottom container row background color
                },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#fafafa", // Odd row background
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff", // Even row background
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e0f7fa", // Row hover color
              },
              "& .MuiDataGrid-cell": {
                color: "#000", // Cell text color
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f5f5f5", // Footer background
              },
            }}
          >
            <DataGrid
   rowHeight={40} 
localeText={{
              noRowsLabel: "No data available.",  // Custom "No rows" message
            }}
              rows={matches}
              columns={columns}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              autoHeight
              disableSelectionOnClick
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </div>
      );
    else if (activeTab == 1 && activeTabSport == 1)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Completed Soccer Matches
          </div>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black !important", // Header background color
                color: "#fff", // Header text color
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when not sorted
              },
              "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when sorted
              },
              "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in descending order
                },
              "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in ascending order
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Top container row background color
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                {
                  backgroundColor: "blue", // Bottom container row background color
                },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#fafafa", // Odd row background
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff", // Even row background
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e0f7fa", // Row hover color
              },
              "& .MuiDataGrid-cell": {
                color: "#000", // Cell text color
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f5f5f5", // Footer background
              },
            }}
          >
            <DataGrid
   rowHeight={40} 
localeText={{
              noRowsLabel: "No data available.",  // Custom "No rows" message
            }}
              rows={matches}
              columns={columns}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              autoHeight
              disableSelectionOnClick
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </div>
      );
    else if (activeTab == 1 && activeTabSport == 2)
      return (
        <div style={{ height: 800, width: "100%" }}>
          <div style={{ fontWeight: "Bold", paddingBottom: "10px" }}>
            Completed Tennis Matches
          </div>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black !important", // Header background color
                color: "#fff", // Header text color
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when not sorted
              },
              "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when sorted
              },
              "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in descending order
                },
              "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in ascending order
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Top container row background color
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                {
                  backgroundColor: "blue", // Bottom container row background color
                },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#fafafa", // Odd row background
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff", // Even row background
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e0f7fa", // Row hover color
              },
              "& .MuiDataGrid-cell": {
                color: "#000", // Cell text color
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f5f5f5", // Footer background
              },
            }}
          >
            <DataGrid
   rowHeight={40} 
localeText={{
              noRowsLabel: "No data available.",  // Custom "No rows" message
            }}
              rows={matches}
              columns={columns}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              autoHeight
              disableSelectionOnClick
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </div>
      );
    else return <></>;
  };

  return (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          placeItems: "center",
          background: "",
          borderRadius: "8px",
          marginBottom: "8px",
        }}
      >
        <div style={{ backgroundColor: "white" }}></div>

        <Tabs
          value={activeTabSport}
          sx={{
            ".Mui-selected": {
              color: `#fff`,
              outline: "none",
            },
            "&:hover": {
              outline: "none",
            },
          }}
          TabIndicatorProps={{ style: { background: "#18aec5" } }}
          aria-label="tabs example"
          onChange={(e, newVal) => {
            setActiveTabSport(newVal);
          }}
        >
          <Tab
            value={0}
            label={
              <span
                style={{ color: activeTabSport == 0 ? "#18aec5" : "#808080" }}
              >
                Cricket
              </span>
            }
          />
          <Tab
            value={1}
            label={
              <span
                style={{ color: activeTabSport == 1 ? "#18aec5" : "#808080" }}
              >
                Soccer
              </span>
            }
          />
          <Tab
            value={2}
            label={
              <span
                style={{ color: activeTabSport == 2 ? "#18aec5" : "#808080" }}
              >
                Tennis
              </span>
            }
          />
        </Tabs>
      </div>
      {returnCurrentTabTable()}
    </div>
  );
}
