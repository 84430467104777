import React, { useEffect, useState } from "react";
import { Box, Button, Container, Switch } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axiosInstance from "../../helpers/axios";
import { GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

function GamesAccess() {
  const [rows, setRows] = useState([]);
  const [tabName, setTabName] = useState(4);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event, data) => {
    // setChecked(event.target.checked);
    const { checked } = event.target;
    const { _id } = data;
    blockMatch(_id, checked);
  };
  useEffect(() => {
    getLiveMatches(1);
  }, []);
  const blockMatch = async (id, checked) => {
    try {
      let res = await axiosInstance.post("/matches/blockingMatch", {
        _id: id,
        is_block: checked,
      });
      
    } catch (err) {
      console.log(err);
    } finally {
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "NO.",
      width: 50,
    },
    {
      field: "match_id",
      headerName: "match Id",
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "match_name",
      headerName: "Match Name",
      width: 230,
      disableColumnMenu: true,
    },
    {
      field: "start_time",
      headerName: "Start Time",
      width: 230,
      disableColumnMenu: true,
    },
    {
      field: "odds",
      headerName: "Status",
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Button>
          {params.row.odds[0]?.inplay ? (
            <div className="lvnow-body">
              <div className="lvnow"> </div>
              <small>Live</small>
            </div>
          ) : (
            <div className="offline-body">
              <div className="offline"> </div>
              <small>Offline</small>
            </div>
          )}
        </Button>
      ),
    },
    {
      field: "_id",
      headerName: "Acton",
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Switch
          defaultChecked={params.row.is_block}
          onChange={(e) => handleChange(e, params.row)}
          inputProps={{ "aria-label": "controlled" }}
          size="small"
        />
      ),
    },
  ];

  const getLiveMatches = async (match_id) => {
    setLoading(true);
    try {
      let res = await axiosInstance.get(
        `/matches/getLiveMatches/${match_id}?page=1&limit=100`
      );
      if (res.data.status) {
        setRows(res.data.dataobj.map((value,index)=>({id:index+1,...value})));
        setTabName(match_id)
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            // background: "#FFFFFF",
            width: "100%",
            padding: "8px 0px 8px 16px",
            borderRadius: "10px",
            boxSizing: "border-box",
            mb: "100px",
          }}
          className="paper-box"
        >
          <p style={{ fontWeight: "Bold" }}>Games Access</p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
              className={tabName==4 ? "is-active":""}
              onClick={() => getLiveMatches(4)}
            >
              Cricket
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
              className={tabName==1 ? "is-active":""}
              onClick={() => getLiveMatches(1)}
            >
              Soccer
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
              className={tabName==2 ? "is-active":""}
              onClick={() => getLiveMatches(2)}
            >
              Tennis
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
            >
              BasketBall
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
            >
              Hockey
            </div>
          </div>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black !important", // Header background color
                color: "#fff", // Header text color
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when not sorted
              },
              "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when sorted
              },
              "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in descending order
                },
              "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in ascending order
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Top container row background color
                },
              "& .css-163fvz6-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Row background color for top container
                },

              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                {
                  backgroundColor: "blue", // Bottom container row background color
                },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#fafafa", // Odd row background
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff", // Even row background
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e0f7fa", // Row hover color
              },
              "& .MuiDataGrid-cell": {
                color: "#000", // Cell text color
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f5f5f5", // Footer background
              },
            }}
          >
            <DataGrid
   rowHeight={40} 
              localeText={{
                noRowsLabel: "No data available.", // Custom "No rows" message
              }}
              rows={rows}
              columns={columns}
              getRowId={(row) => row._id}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              autoHeight
              loading={loading}
              rowHeight={30}
              pageSizeOptions={[10, 25, 50, 100]}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </div>
      </Box>
    </Container>
  );
}

export default GamesAccess;
