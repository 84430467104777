import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{ display: "flex", justifyContent: "flex-end" }}
      className="csv-download"
    >
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
const BookmakerPopup = ({ bookmakerData }) => {
  const [open, setOpen] = useState(false);

  const positionKeys = Object.keys(bookmakerData[0]?.position || {});
  const columns = [
    { field: "userName", headerName: "User Name", width: 150 },
    ...positionKeys.map((key) => ({
      field: key,
      headerName: key,
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            color: params.value < 0 ? "red" : "green",
            textTransform:"capitalize"
          }}
        >
          {params.value}
        </div>
      ),
    })),
  ];

  const rows = bookmakerData.map((item) => {
    const row = {
      id: item._id,
      userName: item.userDetails.first_name || "N/A",
    };

    Object.entries(item.position).forEach(([key, value]) => {
      row[key] = -value;
    });

    return row;
  });

  return (
    <>
      <Button
        variant="contained"
        style={{
          backgroundColor: "#80c2f1",
          width: "20%",
          height: "20px",
          fontSize: "10px",
        }}
        onClick={() => setOpen(true)}
      >
        Book
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle>Bookmaker Bets</DialogTitle>
        <DialogContent style={{ height: 400, width: 600 }}>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black !important", // Header background color
                color: "#fff", // Header text color
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when not sorted
              },
              "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when sorted
              },
              "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in descending order
                },
              "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in ascending order
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Top container row background color
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                {
                  backgroundColor: "blue", // Bottom container row background color
                },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#fafafa", // Odd row background
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff", // Even row background
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e0f7fa", // Row hover color
              },
              "& .MuiDataGrid-cell": {
                color: "#000", // Cell text color
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f5f5f5", // Footer background
              },
            }}
          >
            <DataGrid
   rowHeight={40} 
              localeText={{
                noRowsLabel: "No data available.", // Custom "No rows" message
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default BookmakerPopup;
