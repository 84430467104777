import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import axiosInstance from "../../helpers/axios";
import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NewClientForm from "./NewClientForm";
import EditIcon from "@mui/icons-material/Edit";
import PasswordIcon from "@mui/icons-material/Password";
import { IconButton } from "@mui/material";

const columns = [
  {
    field: "_id",
    headerName: "ID",
    width: 200,
    disableColumnMenu: true,
  },
  {
    field: "username",
    headerName: "UserName",
    width: 150,
    disableColumnMenu: true,
  },
  {
    field: "first_name",
    headerName: "First name",
    width: 150,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <>
          <span
            style={{
              textTransform: "capitalize",
            }}
          >
            {params.value}
          </span>
        </>
      );
    },
  },
  {
    field: "last_name",
    headerName: "Last name",
    width: 150,
    disableColumnMenu: true,
  },
  // {
  //   field: "fix_limit",
  //   headerName: "Fix Limit",
  //   type: "number",
  //   headerAlign: "start",
  //   align: "start",
  //   width: 120,
  //   disableColumnMenu: true,
  // },
  {
    field: "my_match_share",
    headerName: "My Share",
    description: "This column has a value getter and is not sortable.",
    width: 150,
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.my_match_share || ""}`,
  },

  {
    field: "exposure",
    headerName: "Exposure",
    width: 150, // Adjust width as needed
    disableColumnMenu: true,
    valueGetter: (value, row) => `${row?.exposure || "0"}`,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function MyClientsTable() {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleFetchRows = async () => {
    try {
      const userDetails = JSON.parse(localStorage.getItem("userProfile"));
      const response = await axiosInstance.get(
        `/users/getMyClient?_id=${userDetails?._id}`
      );
      const data = response.data;
      setRows(data?.dataobj);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAddUser = (newUser) => {
    setRows([...rows, newUser]);
  };

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    handleFetchRows();
  }, []);
  return (
    <Box>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobile ? "flex-start" : "center"}
        spacing={isMobile ? 2 : 0}
        mb={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          size="small"
          fullWidth={isMobile}
        />
      </Stack>

      <div style={{ height: 400, width: "100%" }}>
        <Box
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black !important", // Header background color
              color: "#fff", // Header text color
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when not sorted
            },
            "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when sorted
            },
            "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in descending order
              },
            "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in ascending order
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
              {
                backgroundColor: "black", // Top container row background color
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
              {
                backgroundColor: "blue", // Bottom container row background color
              },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#fafafa", // Odd row background
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#fff", // Even row background
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#e0f7fa", // Row hover color
            },
            "& .MuiDataGrid-cell": {
              color: "#000", // Cell text color
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#f5f5f5", // Footer background
            },
          }}
        >
          <DataGrid
   rowHeight={40} 
            localeText={{
              noRowsLabel: "No data available.", // Custom "No rows" message
            }}
            rows={filteredRows.map((row) => ({
              ...row,
            }))}
            columns={columns}
            getRowId={(row) => row._id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
        <Modal open={open} onClose={handleClose}>
          <div
            style={{
              padding: 20,
              background: "white",
              margin: "50px auto",
              maxWidth: 800,
              borderRadius: "15px",
            }}
          >
            <NewClientForm onSubmit={handleAddUser} onClose={handleClose} />
          </div>
        </Modal>
      </div>
    </Box>
  );
}
