import React, { useEffect } from "react";
import { Box } from "@mui/material";
import LoginForm from "../components/LoginForm";
import { useLocation, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard", { replace: true });
    }
  }, [isAuthenticated, location, navigate]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#282c34",
        flexDirection: "column",
      }}
    >
      <img
        src="/assets/logo2.png"
        alt="Logo"
        style={{ maxWidth: "150px", marginBottom: "20px" }}
      />
      <LoginForm />
    </Box>
  );
};

export default LoginPage;
