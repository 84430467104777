import React, { useState, useEffect } from "react";
import { Container, Box, Typography, Checkbox } from "@mui/material";
import axios from "../../helpers/axios";
import { DataGrid } from "@mui/x-data-grid";
import { GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

const SeriesListingTable = () => {
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    const fetchSeriesData = async () => {
      try {
        const result = await axios.get("/series/getSeries");
        setSeriesData(result.data.dataobj);
      } catch (err) {
        console.log(err);
      }
    };

    fetchSeriesData();
  }, []);

  const columns = [
    { field: "srNo", headerName: "Sr No.", flex: 1 },
    { field: "seriesName", headerName: "Series Name", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.isSelected}
          onChange={(e) =>
            handleCheckboxChange(params.row.id, e.target.checked)
          }
        />
      ),
    },
  ];

  const handleCheckboxChange = (id, isChecked) => {
    const updatedData = seriesData.map((item) =>
      item.id === id ? { ...item, isSelected: isChecked } : item
    );
    setSeriesData(updatedData);
  };

  const mockSeriesData = [
    { id: 1, srNo: 1, seriesName: "Premier League", isSelected: false },
    { id: 2, srNo: 2, seriesName: "Champions League", isSelected: true },
    { id: 3, srNo: 3, seriesName: "La Liga", isSelected: false },
    { id: 4, srNo: 4, seriesName: "Bundesliga", isSelected: true },
    { id: 5, srNo: 5, seriesName: "Serie A", isSelected: false },
    { id: 6, srNo: 6, seriesName: "UEFA Europa League", isSelected: false },
    { id: 7, srNo: 7, seriesName: "World Cup", isSelected: true },
  ];

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Series Listing
        </Typography>
        <Box sx={{ height: 600, width: "100%" }}>
          <Box
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "black !important", // Header background color
                color: "#fff", // Header text color
              },
              "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when not sorted
              },
              "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                color: "#fff", // Sort icon color when sorted
              },
              "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in descending order
                },
              "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                {
                  color: "#fff", // Sort icon color when sorted in ascending order
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                {
                  backgroundColor: "black", // Top container row background color
                },
              "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                {
                  backgroundColor: "blue", // Bottom container row background color
                },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#fafafa", // Odd row background
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#fff", // Even row background
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e0f7fa", // Row hover color
              },
              "& .MuiDataGrid-cell": {
                color: "#000", // Cell text color
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "#f5f5f5", // Footer background
              },
            }}
          >
            <DataGrid
   rowHeight={40} 
localeText={{
              noRowsLabel: "No data available.",  // Custom "No rows" message
            }}
              rows={mockSeriesData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              pagination
              autoHeight
              disableSelectionOnClick
              slots={{
                toolbar: CustomToolbar,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SeriesListingTable;
