import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import {
  Group,
  Favorite,
  Person,
  TrendingUp,
  Settings,
  ManageAccounts,
  AttachMoney,
} from "@mui/icons-material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import axiosInstance from "../../helpers/axios";
import Header from "./header";
import { useMediaQuery } from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
const MenuOptionList = [
  {
    title: "My Market",
    path: "/live-matches" || "/",
    icon: <TrendingUp />,
  },
  {
    title: "Members",
    isExpandable: true,
    options: [
      {
        title: "Super Admin",
        path: "/manage/super-admin",
        icon: <Person />,
        allowedRoles: ["company"],
      },
      {
        title: "Admin",
        path: "/manage/admin",
        icon: <Person />,
        allowedRoles: ["company", "super_admin"],
      },
      {
        title: "Super Master",
        path: "/members/super-master",
        icon: <Person />,
        allowedRoles: ["company", "super_admin", "admin"],
      },
      {
        title: "Master",
        path: "/members/masters",
        icon: <Person />,
        allowedRoles: ["company", "super_admin", "admin", "super_master"],
      },
      {
        title: "User",
        path: "/manage/user",
        icon: <Person />,
        allowedRoles: [
          "super_admin",
          "company",
          "admin",
          "super_master",
          "master",
        ],
      },
      {
        title: "Client Statements",
        path: "/members/client-statement",
        icon: <Person />,
        allowedRoles: [
          "super_admin",
          "company",
          "admin",
          "super_master",
          "master",
        ],
      },
      {
        title: "Blocked Clients",
        path: "/clients/blocked-clients",
        icon: <Person />,
      },
    ],
    icon: <Group />,
  },

  {
    title: "Collection Report",
    path: "/ledger/collection-report",
    icon: <Person />,
  },

  {
    title: "Settings",
    isExpandable: true,
    options: [
      {
        title: "Profile",
        path: "/profile",
        icon: <Person />,
      },
      {
        title: "Block Sports",
        path: "/block-sports",
        icon: <Person />,
        allowedRoles: ["company"],
      },
      {
        title: "Manage Password",
        path: "/manage-password",
        icon: <Person />,
      },
      {
        title: "My Statement",
        path: "/ledger/my-statement",
        icon: <Person />,
      },

      {
        title: "Language",
        path: "/language",
        icon: <Person />,
      },
      {
        title: "Games",
        path: "/games",
        icon: <SportsEsportsIcon />,
      },
    ],
    icon: <Settings />,
  },
];

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const userRole = localStorage.getItem("user_type");

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const currentPath = location.pathname;
    const selectedItem = MenuOptionList.find(
      (item) =>
        item.path === currentPath ||
        (item.isExpandable &&
          item.options.some((subItem) => subItem.path === currentPath))
    );

    if (selectedItem) {
      setSelectedMenu(selectedItem.title);
      if (selectedItem.isExpandable) {
        setExpandedMenu(selectedItem.title);
        const accessibleSubMenu = selectedItem.options.find((subItem) =>
          subItem.allowedRoles?.includes(userRole)
        );
        if (accessibleSubMenu) {
          setSelectedSubMenu(accessibleSubMenu.path);
          navigate(accessibleSubMenu.path);
        } else {
          setSelectedSubMenu(null);
        }
      } else {
        setSelectedSubMenu(currentPath);
        setExpandedMenu(null);
      }
    } else {
      setSelectedMenu("Dashboard");
      setSelectedSubMenu("/");
      setExpandedMenu(null);
    }
  }, [userRole]);

  const handleMenuClick = (item) => {
    if (item.isExpandable) {
      setExpandedMenu(expandedMenu === item.title ? null : item.title);

      const accessibleSubMenu = item.options.find(
        (subItem) =>
          !subItem.allowedRoles || subItem.allowedRoles.includes(userRole)
      );

      if (accessibleSubMenu) {
        setSelectedMenu(item.title);
        setSelectedSubMenu(accessibleSubMenu.path);
        navigate(accessibleSubMenu.path);
      }
    } else {
      if (item.allowedRoles && !item.allowedRoles.includes(userRole)) {
        return;
      }
      navigate(item.path);
      setSelectedMenu(item.title);
      setSelectedSubMenu(null);
      setExpandedMenu(null);
    }
  };

  const handleSubMenuClick = (path) => {
    const selectedItem = MenuOptionList.find(
      (item) =>
        item.options && item.options.some((subItem) => subItem.path === path)
    );
    if (selectedItem) {
      const subItem = selectedItem.options.find(
        (subItem) => subItem.path === path
      );
      if (!subItem.allowedRoles || subItem.allowedRoles.includes(userRole)) {
        navigate(path);
        setSelectedSubMenu(path);
        setSelectedMenu(selectedItem.title);
      }
    }
  };

  const renderMenuItems = (items) => {
    return (
      <Box sx={{ width: "100%" }} className="menus">
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "row" : "row",
            justifyContent: isMobile ? "space-evenly" : "flex-start",
            position: isMobile ? "fixed" : "relative",
            bottom: isMobile ? 0 : "unset",
            width: isMobile ? "100%" : "50%",
            // padding: isMobile ? "8px 0" : "unset",
            backgroundColor: isMobile ? "#b025a8" : "",
            zIndex: 10,
            pointerEvents: "none",
          }}
        >
          {items
            .filter(
              (item) =>
                !item.allowedRoles || item.allowedRoles.includes(userRole)
            )
            .map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: !isMobile ? "flex" : "block",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  margin: isMobile ? 0 : 1,
                  flex: isMobile ? "1 1 auto" : 1,
                  textAlign: "center",
                  pointerEvents: "auto",
                  padding: isMobile ? "6px 0" : "",
                }}
              >
                <IconButton
                  onClick={() => handleMenuClick(item)}
                  sx={{
                    borderRadius: isMobile ? "50%" : "2%",
                    padding: isMobile ? "0px" : 1,
                    justifyContent: "center",
                    color: selectedMenu === item.title ? "#fff" : "inherit",
                    pointerEvents: "auto",
                  }}
                >
                  {item.icon}
                  {!isMobile && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: selectedMenu === item.title ? "#fff" : "inherit",
                        fontWeight:
                          selectedMenu === item.title ? "bold" : "normal",
                      }}
                    >
                      &nbsp;{item.title}
                    </Typography>
                  )}
                </IconButton>
                {isMobile && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: selectedMenu === item.title ? "#fff" : "inherit",
                      fontWeight: "normal",
                      fontSize: "9px",
                    }}
                  >
                    {item.title}
                  </Typography>
                )}
              </Box>
            ))}
        </Box>

        {expandedMenu && !isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#f0f0f0",
              padding: 1,
              width: "100%",
              boxSizing: "border-box",
              pointerEvents: "auto",
            }}
          >
            {items
              .filter(
                (item) => item.title === expandedMenu && item.isExpandable
              )
              .map((item) =>
                item.options
                  .filter(
                    (subItem) =>
                      !subItem.allowedRoles ||
                      subItem.allowedRoles.includes(userRole)
                  )
                  .map((subItem, subIndex) => (
                    <Box
                      key={subIndex}
                      sx={{
                        color:
                          selectedSubMenu === subItem.path
                            ? "#b025a8"
                            : "inherit",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "4px 8px",
                        textAlign: "center",
                        flex: 1,
                      }}
                      onClick={() => handleSubMenuClick(subItem.path)}
                    >
                      {subItem.icon}
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "0.75rem",
                          color:
                            selectedSubMenu === subItem.path
                              ? "#b025a8"
                              : "inherit",
                          fontWeight:
                            selectedSubMenu === subItem.path
                              ? "bold"
                              : "normal",
                        }}
                      >
                        {subItem.title}
                      </Typography>
                    </Box>
                  ))
              )}
          </Box>
        )}

        {expandedMenu && isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#f0f0f0",
              padding: 2,
              width: "100%",
              boxSizing: "border-box",
              pointerEvents: "auto",
            }}
          >
            <Select
              value={selectedSubMenu || ""}
              onChange={(e) => handleSubMenuClick(e.target.value)}
              displayEmpty
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "4px",
              }}
              size="small"
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Select an option</em>;
                }
                return items
                  .find(
                    (item) => item.title === expandedMenu && item.isExpandable
                  )
                  ?.options.find((subItem) => subItem.path === selected)?.title;
              }}
            >
              {items
                .filter(
                  (item) => item.title === expandedMenu && item.isExpandable
                )
                .map((item) =>
                  item.options
                    .filter(
                      (subItem) =>
                        !subItem.allowedRoles ||
                        subItem.allowedRoles.includes(userRole)
                    )
                    .map((subItem, subIndex) => (
                      <MenuItem key={subIndex} value={subItem.path}>
                        {subItem.icon}
                        <Typography
                          variant="body2"
                          sx={{
                            fontSize: "0.75rem",
                            marginLeft: "8px",
                            color:
                              selectedSubMenu === subItem.path
                                ? "#000"
                                : "inherit",
                            fontWeight:
                              selectedSubMenu === subItem.path
                                ? "bold"
                                : "normal",
                          }}
                        >
                          {subItem.title}
                        </Typography>
                      </MenuItem>
                    ))
                )}
            </Select>
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    setUserProfile(JSON.parse(localStorage.getItem("userProfile")));
  }, []);
  return (
    <>
      <Header onOpenNav={() => setOpen(true)} userProfile={userProfile} />
      <div>
        {" "}
        <img src="/assets/admin-panel.png" className="banner-img" alt="img" />
      </div>
      <Box
        sx={{
          // backgroundColor: "#f5f5f5",
          padding: isMobile ? "0px" : "16px",
          overflowX: "auto",
          // background:`url("/assets/admin-panel.png")`
        }}
      >
        <Typography
          variant="h5"
          color="black"
          gutterBottom
          sx={{
            paddingLeft: "5px",
            textAlign: "left",
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
            fontWeight: "500",
            textTransform:"capitalize"
          }}
        >
          Welcome, {userProfile?.username || "User"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "space-evenly",
          }}
        >
          {renderMenuItems(MenuOptionList)}
        </Box>
      </Box>
      <Outlet />
    </>
  );
}
