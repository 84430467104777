import React, { useState, useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  IconButton,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
import axios from "../../helpers/axios";
import { Settings, Add, Edit, Remove } from "@mui/icons-material";

function CommisionAndLimits() {
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [colData, setColData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const getCommissionAndLimit = async () => {
    try {
      const res = await axios.get("/users/getCommissionAndLimit");
      if (res.data.status) {
        setColData(res.data.dataobj);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCommissionAndLimit();
  }, []);

  useEffect(() => {
    function filterData(searchQuery) {
      const query = searchQuery.toLowerCase();
      return colData.filter((user) =>
        Object.values(user).some(
          (value) =>
            typeof value === "string" && value.toLowerCase().includes(query)
        )
      );
    }
    setFilteredData(filterData(filterText));
  }, [filterText, colData]);

  const depositBalanceToUser = async (amount, deposit_to, deposited_by) => {
    try {
      const res = await axios.post("/users/deposit", {
        amount,
        deposit_to,
        deposited_by,
      });
      if (res.data.status) {
        Swal.fire({
          text: "Amount Deposited!",
          timer: 2000,
          icon: "success",
        });
        getCommissionAndLimit();
      } else {
        Swal.fire({
          icon: "error",
          text: res.data.msg,
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        text: "Something went wrong! please contact admin.",
      });
    }
  };

  const withdrawBalanceFromUser = async (
    amount,
    withdraw_from,
    withdrawn_by
  ) => {
    try {
      const res = await axios.post("/users/withdraw", {
        amount,
        withdraw_from,
        withdrawn_by,
      });
      if (res.data.status) {
        Swal.fire({
          text: "Amount Withdrawn!",
          timer: 2000,
          icon: "success",
        });
        getCommissionAndLimit();
      } else {
        Swal.fire({
          icon: "error",
          text: res.data.msg,
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: "error",
        text: "Something went wrong! please contact admin.",
      });
    }
  };

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  };

  const subHeaderComponent = useMemo(
    () => (
      <TextField
        variant="outlined"
        placeholder="Search Here"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        size="small"
      />
    ),
    [filterText, resetPaginationToggle]
  );

  const limitsColumns = [
    { id: "credits", label: "Current Limit" },
    { id: "down_limit", label: "Down Limit" },
    {
      id: "actions",
      label: "Action",
      format: (value, row) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="primary"
            onClick={() => {
              Swal.fire({
                title: "Deposit Balance to User",
                input: "text",
                inputAttributes: {
                  autocapitalize: "off",
                },
                background: "#2b2d3a",
                color: "#fff",
                showCancelButton: true,
                confirmButtonText: "Deposit",
                showLoaderOnConfirm: true,
                preConfirm: (amount) => {
                  depositBalanceToUser(
                    amount,
                    row._id,
                    localStorage.getItem("_id")
                  );
                },
                allowOutsideClick: () => !Swal.isLoading(),
              });
            }}
          >
            <Add />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => {
              Swal.fire({
                title: "Withdraw Balance from User",
                input: "text",
                inputAttributes: {
                  autocapitalize: "off",
                },
                background: "#2b2d3a",
                color: "#fff",
                showCancelButton: true,
                confirmButtonText: "Withdraw",
                showLoaderOnConfirm: true,
                preConfirm: (amount) => {
                  withdrawBalanceFromUser(
                    amount,
                    row._id,
                    localStorage.getItem("_id")
                  );
                },
                allowOutsideClick: () => !Swal.isLoading(),
              });
            }}
          >
            <Remove />
          </IconButton>
          <IconButton color="inherit">
            <Settings />
          </IconButton>
        </div>
      ),
    },
  ];

  const commissionColumns = [
    { id: "username", label: "Client Name" },
    { id: "match_commission", label: "Match Comm." },
    { id: "session_commission", label: "Session Comm." },
  ];

  const TableElement = ({ commissionData, title, limitsData }) => (
    <Paper style={{ padding: "16px", margin: "16px 0" }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {commissionColumns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              {limitsColumns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {commissionData.map((row) => (
              <TableRow key={row._id}>
                {commissionColumns.map((column) => (
                  <TableCell key={column.id}>{row[column.id]}</TableCell>
                ))}
                {limitsColumns.map((column) => (
                  <TableCell key={column.id}>
                    {column.format
                      ? column.format(row[column.id], row)
                      : row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <div>
      {subHeaderComponent}
      <TableElement
        title={"Super Stockist"}
        commissionData={colData.filter(
          (user) => user.user_type === "super_stockist"
        )}
        limitsData={colData.filter(
          (user) => user.user_type === "super_stockist"
        )}
      />
      <TableElement
        title={"Stockist"}
        commissionData={colData.filter((user) => user.user_type === "stockist")}
        limitsData={colData.filter((user) => user.user_type === "stockist")}
      />
      <TableElement
        title={"Agent"}
        commissionData={colData.filter((user) => user.user_type === "agent")}
        limitsData={colData.filter((user) => user.user_type === "agent")}
      />
      <TableElement
        title={"All Users"}
        commissionData={colData}
        limitsData={colData}
      />
    </div>
  );
}

export default CommisionAndLimits;
