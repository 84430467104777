import React, { useEffect, useState } from "react";
import axios from "../../helpers/axios";
import {
  Grid,
  Typography,
  CircularProgress,
  Box,
  Container,
  Paper,
} from "@mui/material";

export default function Dashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [localData, setLocalData] = useState();

  useEffect(() => {
    getUserData();
    setLocalData(localStorage.getItem("userProfile"));
  }, []);

  const getUserData = async () => {
    try {
      const response = await axios.get("/users/get-user", {
        params: {
          username: JSON.parse(localStorage.getItem("userProfile")).username,
        },
      });
      setUserData(response.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  const userProfile = JSON.parse(localStorage.getItem("userProfile"));

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginTop: "20px",
        paddingBottom: "70px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h5"
          color="black"
          gutterBottom
          sx={{
            paddingLeft: "5px",
            textAlign: "left",
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
            fontWeight: "500",
          }}
        >
          Welcome, {userProfile?.username || "User"}
        </Typography>
        <Typography
          variant="body1"
          color="#BCBCBF"
          gutterBottom
          sx={{
            paddingLeft: "5px",
            textAlign: "left",
            marginTop: "10px",
            fontSize: { xs: "0.875rem", sm: "1rem" },
            fontWeight: "500",
          }}
        >
          User Information
        </Typography>
        <Paper
          sx={{
            borderRadius: "15px",
            padding: "20px",
            boxShadow: 3,
          }}
          className="paper-box"
        >
          <Grid container spacing={3}>
            {[
              { label: "My Username:", value: userProfile?.username },
              { label: "My Level:", value: userProfile?.user_type },
              { label: "My Fix Limit:", value: userProfile?.fix_limit },
              {
                label: "Maximum My Share:",
                value: userProfile?.my_match_share,
              },
              {
                label: "Minimum Company Share:",
                value: userProfile?.other_match_share,
              },
              {
                label: "Match Commission:",
                value: userProfile?.match_commission,
              },
              {
                label: "Session Commission:",
                value: userProfile?.session_commission,
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Grid container>
                  <Grid item xs={6} sx={{ paddingRight: 2 }}>
                    <Typography
                      variant="h6"
                      color="#64646B"
                      sx={{
                        fontSize: { xs: "0.875rem", sm: "1rem" },
                        fontWeight: "bold",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="h5"
                      color="black"
                      sx={{
                        fontSize: { xs: "1rem", sm: "1.25rem" },
                        fontWeight: "500",
                      }}
                    >
                      {item.value || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
}
