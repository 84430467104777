import React, { useState, useMemo, useEffect } from "react";
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  Stack,
  TextField,
  Select,
  InputLabel,
  FormControl,
  Container,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axiosInstance from "../helpers/axios";

const accountTypes = ["All", "Balance Report", "Game Report"];
const gameNames = ["All", "Cricket", "Soccer", "Tennis"];
const clients = ["Super Admin", "Admin", "User", "Agent"];
const dummyData = [
  {
    id: 1,
    date: "2024-09-01",
    username: "user1",
    credit: 100,
    debit: 50,
    description: "Game Win",
    closing: 50,
  },
  {
    id: 2,
    date: "2024-09-02",
    username: "user2",
    credit: 200,
    debit: 100,
    description: "Game Loss",
    closing: 100,
  },
  {
    id: 3,
    date: "2024-09-03",
    username: "user3",
    credit: 150,
    debit: 75,
    description: "Balance Update",
    closing: 75,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}

const columns = [
  { field: "date", headerName: "Date", width: 130, sortable: true },
  { field: "username", headerName: "Username", width: 130, sortable: true },
  { field: "credit", headerName: "Credit", width: 100, sortable: true },
  { field: "debit", headerName: "Debit", width: 100, sortable: true },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    sortable: true,
  },
];

export default function AccountStatementReports() {
  const [accountType, setAccountType] = useState("All");
  const [gameName, setGameName] = useState("All");
  const [client, setClient] = useState("Super Admin");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [searchTerm, setSearchTerm] = useState("");

  const handleAccountTypeChange = (event) => setAccountType(event.target.value);
  const handleGameNameChange = (event) => setGameName(event.target.value);
  const handleClientChange = (event) => setClient(event.target.value);
  const handleSearchTermChange = (event) => setSearchTerm(event.target.value);
  const [rows, setRows] = useState([]);

  const filteredRows = useMemo(() => {
    return dummyData.filter((row) => {
      const matchSearchTerm = Object.values(row).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
      const matchAccountType =
        accountType === "All" || row.description.includes(accountType);
      const matchGameName =
        gameName === "All" || row.description.includes(gameName);
      const matchClient = client === "All" || row.username.includes(client);

      return (
        matchSearchTerm && matchAccountType && matchGameName && matchClient
      );
    });
  }, [searchTerm, accountType, gameName, client]);

  const handleFetchRows = async () => {
    try {
      if (fromDate && toDate) {
        const userDetails = JSON.parse(localStorage.getItem("userProfile"));
        const response = await axiosInstance.get(
          `/users/getUserStatement?user_id=${userDetails?._id}&startDate=${fromDate}&endDate=${toDate}`
        );
        const data = response.data;
        setRows(data?.dataobj);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleFetchRows();
  }, [fromDate, toDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg">
        {" "}
        <Box padding={2}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="flex-start"
            mb={2}
          >
            <FormControl fullWidth sx={{ minWidth: 150 }}>
              <InputLabel>Account Type</InputLabel>
              <Select
                value={accountType}
                label="Account Type"
                onChange={handleAccountTypeChange}
                size="small"
              >
                {accountTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ minWidth: 150 }}>
              <InputLabel>Game Name</InputLabel>
              <Select
                value={gameName}
                label="Game Name"
                onChange={handleGameNameChange}
                size="small"
              >
                {gameNames.map((game) => (
                  <MenuItem key={game} value={game}>
                    {game}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ minWidth: 150 }}>
              <InputLabel>Search By Client</InputLabel>
              <Select
                label="Search By Client"
                value={client}
                onChange={handleClientChange}
                size="small"
              >
                {clients.map((clientType) => (
                  <MenuItem key={clientType} value={clientType}>
                    {clientType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchTermChange}
              size="small"
              fullWidth
              sx={{ minWidth: 150 }}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} mb={2}>
            <DatePicker
              label="From"
              variant="outlined"
              sx={{ minWidth: 150 }}
              value={fromDate}
              onChange={(newDate) => setFromDate(newDate)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />

            <DatePicker
              label="To"
              value={toDate}
              onChange={(newDate) => setToDate(newDate)}
              renderInput={(params) => <TextField {...params} size="small" />}
            />
          </Stack>

          <div style={{ height: 400, width: "100%" }}>
            <Box
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "black !important", // Header background color
                  color: "#fff", // Header text color
                },
"& .css-163fvz6-MuiDataGrid-root .MuiDataGrid-container--top [role=row]": {
            backgroundColor: "black", // Set the background color for rows in the top container
          },
          "& .css-163fvz6-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]": {
            backgroundColor: "black", // Set the background color for rows in the bottom container
          },
                "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
                  color: "#fff", // Sort icon color when not sorted
                },
                "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
                  color: "#fff", // Sort icon color when sorted
                },
                "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
                  {
                    color: "#fff", // Sort icon color when sorted in descending order
                  },
                "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
                  {
                    color: "#fff", // Sort icon color when sorted in ascending order
                  },
                "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
                  {
                    backgroundColor: "black", // Top container row background color
                  },
                "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
                  {
                    backgroundColor: "blue", // Bottom container row background color
                  },
                "& .MuiDataGrid-row:nth-of-type(odd)": {
                  backgroundColor: "#fafafa", // Odd row background
                },
                "& .MuiDataGrid-row:nth-of-type(even)": {
                  backgroundColor: "#fff", // Even row background
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#e0f7fa", // Row hover color
                },
                "& .MuiDataGrid-cell": {
                  color: "#000", // Cell text color
                },
                "& .MuiDataGrid-footerContainer": {
                  backgroundColor: "#f5f5f5", // Footer background
                },
              }}
            >
              <DataGrid
   rowHeight={40} 
                localeText={{
                  noRowsLabel: "No data available.", // Custom "No rows" message
                }}
                rows={rows}
                getRowId={(row) => row._id}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </Box>
          </div>
        </Box>
      </Container>
    </LocalizationProvider>
  );
}
