import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { MenuItem, Typography } from "@mui/material";
import sha256 from "sha256";

const NewClientForm = ({ onClose }) => {
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      confirmPassword: "",
      first_name: "",
      last_name: "",
      fix_limit: "",
      my_match_share: "",
      match_commission: "",
      other_match_share: "",
      session_commission: "",
      phone: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      first_name: Yup.string().required("First Name is required"),
      fix_limit: Yup.number().required("Fix Limit is required").min(0),
      my_match_share: Yup.number()
        .required("Match Share is required")
        .min(0)
        .max(100),
      match_commission: Yup.number()
        .required("Match Commission is required")
        .min(0)
        .max(2),
      other_match_share: Yup.number()
        .required("Other Match Share is required")
        .min(0)
        .max(100),
      session_commission: Yup.number()
        .required("Session Commission is required")
        .min(0)
        .max(2),
    }),
    onSubmit: (values) => {
      const encryptedPassword = sha256(values.password).toString();

      const userData = {
        ...values,
        password: encryptedPassword,
      };
    },
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        New Client
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="username"
            name="username"
            placeholder="Username"
            variant="outlined"
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            variant="outlined"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
            variant="outlined"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="first_name"
            name="first_name"
            placeholder="Full Name"
            variant="outlined"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.first_name && Boolean(formik.errors.first_name)
            }
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="fix_limit"
            name="fix_limit"
            type="number"
            placeholder="Fix Limit"
            variant="outlined"
            value={formik.values.fix_limit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.fix_limit && Boolean(formik.errors.fix_limit)}
            helperText={formik.touched.fix_limit && formik.errors.fix_limit}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="my_match_share"
            name="my_match_share"
            type="number"
            placeholder="My Match Share"
            variant="outlined"
            value={formik.values.my_match_share}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.my_match_share &&
              Boolean(formik.errors.my_match_share)
            }
            helperText={
              formik.touched.my_match_share && formik.errors.my_match_share
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="match_commission"
            name="match_commission"
            type="number"
            placeholder="Match Commission"
            variant="outlined"
            value={formik.values.match_commission}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.match_commission &&
              Boolean(formik.errors.match_commission)
            }
            helperText={
              formik.touched.match_commission && formik.errors.match_commission
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="other_match_share"
            name="other_match_share"
            type="number"
            placeholder="Other Match Share"
            variant="outlined"
            value={formik.values.other_match_share}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.other_match_share &&
              Boolean(formik.errors.other_match_share)
            }
            helperText={
              formik.touched.other_match_share &&
              formik.errors.other_match_share
            }
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="session_commission"
            name="session_commission"
            type="number"
            placeholder="Session Commission"
            variant="outlined"
            value={formik.values.session_commission}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.session_commission &&
              Boolean(formik.errors.session_commission)
            }
            helperText={
              formik.touched.session_commission &&
              formik.errors.session_commission
            }
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Button variant="outlined" sx={{ mr: 2 }} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#735CFF",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5c49cc",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default NewClientForm;
