import React from "react";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarExport />
//     </GridToolbarContainer>
//   );
// }

export default function CompanyLedgerTable() {
  const columns = [
    { field: "id", headerName: "DATE/TIME", width: 150 },
    { field: "pid", headerName: "ENTRY", width: 150 },
    { field: "title", headerName: "DEBIT", width: 150 },
    { field: "sports", headerName: "CREDIT", width: 150 },
    { field: "date", headerName: "BALANCE", width: 150 },
    { field: "profit_loss", headerName: "NOTE", width: 150 },
  ];
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{ display: "flex", justifyContent: "flex-end" }}
        className="csv-download"
      >
        <GridToolbarExportContainer>
          <GridCsvExportMenuItem  />
          {/* <JsonExportMenuItem /> */}
        </GridToolbarExportContainer>
      </GridToolbarContainer>
    );
  }
  const data = [
    {
      id: "2023-01-01 10:00 AM",
      pid: "ENTRY1",
      title: 50,
      sports: 0,
      date: 50,
      profit_loss: "Initial Balance",
    },
    {
      id: "2023-01-02 02:30 PM",
      pid: "ENTRY2",
      title: 0,
      sports: 30,
      date: 20,
      profit_loss: "Credit from X",
    },
    {
      id: "2023-01-03 08:45 AM",
      pid: "ENTRY3",
      title: 20,
      sports: 0,
      date: 40,
      profit_loss: "Debit for Y",
    },
    {
      id: "2023-01-04 04:15 PM",
      pid: "ENTRY4",
      title: 0,
      sports: 15,
      date: 25,
      profit_loss: "Credit from Z",
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <h2>Agent Ledger</h2>
      <Box
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "black !important", // Header background color
            color: "#fff", // Header text color
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
            color: "#fff", // Sort icon color when not sorted
          },
          "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
            color: "#fff", // Sort icon color when sorted
          },
          "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
            {
              color: "#fff", // Sort icon color when sorted in descending order
            },
          "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
            {
              color: "#fff", // Sort icon color when sorted in ascending order
            },
          "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
            {
              backgroundColor: "black", // Top container row background color
            },
          "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
            {
              backgroundColor: "blue", // Bottom container row background color
            },
          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: "#fafafa", // Odd row background
          },
          "& .MuiDataGrid-row:nth-of-type(even)": {
            backgroundColor: "#fff", // Even row background
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "#e0f7fa", // Row hover color
          },
          "& .MuiDataGrid-cell": {
            color: "#000", // Cell text color
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "#f5f5f5", // Footer background
          },
        }}
      >
        <DataGrid
   rowHeight={40} 
          localeText={{
            noRowsLabel: "No data available.", // Custom "No rows" message
          }}
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          disableSelectionOnClick
          autoHeight
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </Box>
    </div>
  );
}
