import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  TextField,
  Box,
  Menu,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Search as SearchIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import authService from "../../../services/auth.service";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  function logOut() {
    authService.logout();
    navigate("/login", { replace: true });
  }

  const reDirectLink = (link) => {
    handleMenuClose();
    setTimeout(() => {
      navigate(link);
    }, 300);
  };
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#0E191E", height: "60px" }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
          <Typography variant="h6" component="div">
            <img
              src="/assets/logo2.png"
              alt="Logo"
              style={{ maxHeight: "30px" }}
            />
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 2,
            display: "flex",
            justifyContent: isMobile ? "flex-end" : "center",
          }}
        >
          {!isMobile && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  mr: 4,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ animation: "marquee 10s linear infinite" }}
                >
                  Your notification message here
                </Typography>
              </Box>
            </Box>
          )}

          {isMobile ? (
            <SearchIcon />
          ) : (
            <TextField
              variant="outlined"
              placeholder="Search..."
              size="small"
              InputProps={{
                endAdornment: <SearchIcon />,
                sx: {
                  "& fieldset": {
                    border: "none",
                  },
                  "& input::placeholder": {
                    fontSize: "12px", // Change the font size of the placeholder
                    // color: "#888", // Optional: Change the color of the placeholder
                  },
                },
              }}
              sx={{
                borderRadius: "20px",
                backgroundColor: "white",
                width: "300px",
                ml: 0,
                height: "30px",
                "& .MuiInputBase-root": {
                  paddingLeft: "10px", // Adjust left padding for the input (if needed)
                  paddingRight: "8px", // Adjust right padding for the input (if needed)
                  height: "30px", // Adjust the input height to match the TextField height
                },
              }}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
          <IconButton color="inherit" onClick={handleMenuClick}>
            <AccountCircleIcon fontSize="large" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => reDirectLink("profile")}>Profile</MenuItem>
            <MenuItem onClick={logOut}>Logout</MenuItem>
          </Menu>
        </Box>

        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
          <Box sx={{ width: 250 }}>
            <List>
              <ListItem>
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon />,
                  }}
                  sx={{
                    borderRadius: "20px",
                    backgroundColor: "white",
                  }}
                />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Notifications" />
              </ListItem>
              <ListItem button>
                <ListItemText primary="Balance: $1234.56" />
              </ListItem>
              <ListItem button onClick={logOut}>
                <ListItemText primary="Logout" />
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </Toolbar>

      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </AppBar>
  );
};

export default Header;
