import React, { useState, useMemo } from "react";
import {
  DataGrid,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";

import { GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
export default function ProfitAndLoss() {
  const [matches, setMatches] = useState(["a", "b"]);

  const columns = [
    { field: "id", headerName: "Date/Time", width: 150 },
    { field: "pid", headerName: "Match Id", width: 120 },
    { field: "title", headerName: "Match Title", width: 200 },
    { field: "sports", headerName: "Match Earnings", width: 150 },
    { field: "date", headerName: "Commission Plus", width: 150 },
    { field: "profit_loss", headerName: "Commission Minus", width: 150 },
    {
      field: "commissionEarning",
      headerName: "Commission Earning",
      width: 150,
    },
    { field: "totalEarning", headerName: "Total Earning", width: 150 },
  ];

  const data = [
    {
      id: 1,
      pid: "match1",
      title: "Match A",
      sports: 100,
      date: "2023-01-01",
      profit_loss: 50,
    },
    {
      id: 2,
      pid: "match2",
      title: "Match B",
      sports: 120,
      date: "2023-02-01",
      profit_loss: 60,
    },
    {
      id: 3,
      pid: "match3",
      title: "Match C",
      sports: 80,
      date: "2023-03-01",
      profit_loss: 30,
    },
    {
      id: 4,
      pid: "match4",
      title: "Match D",
      sports: 150,
      date: "2023-04-01",
      profit_loss: 75,
    },
    {
      id: 5,
      pid: "match5",
      title: "Match E",
      sports: 90,
      date: "2023-05-01",
      profit_loss: 45,
    },
    {
      id: 6,
      pid: "match6",
      title: "Match F",
      sports: 110,
      date: "2023-06-01",
      profit_loss: 55,
    },
    {
      id: 7,
      pid: "match7",
      title: "Match G",
      sports: 130,
      date: "2023-07-01",
      profit_loss: 65,
    },
    {
      id: 8,
      pid: "match8",
      title: "Match H",
      sports: 70,
      date: "2023-08-01",
      profit_loss: 25,
    },
    {
      id: 9,
      pid: "match9",
      title: "Match I",
      sports: 180,
      date: "2023-09-01",
      profit_loss: 90,
    },
    {
      id: 10,
      pid: "match10",
      title: "Match J",
      sports: 200,
      date: "2023-10-01",
      profit_loss: 100,
    },
    {
      id: 11,
      pid: "match11",
      title: "Match K",
      sports: 120,
      date: "2023-11-01",
      profit_loss: 60,
    },
    {
      id: 12,
      pid: "match12",
      title: "Match L",
      sports: 250,
      date: "2023-12-01",
      profit_loss: 125,
    },
    {
      id: 13,
      pid: "match13",
      title: "Match M",
      sports: 280,
      date: "2024-01-01",
      profit_loss: 140,
    },
    {
      id: 14,
      pid: "match14",
      title: "Match N",
      sports: 160,
      date: "2024-02-01",
      profit_loss: 80,
    },
    {
      id: 15,
      pid: "match15",
      title: "Match O",
      sports: 140,
      date: "2024-03-01",
      profit_loss: 70,
    },
  ];



  return (
    <div>
      <div
        style={{
          backgroundColor: "#c9c9c9",
          borderRadius: "10px",
          padding: "8px 16px",
          marginBottom: "16px",
        }}
      >
        <h4>Summary</h4>
        <div style={{ display: "flex" }}>
          <p>All Time Total :</p>
          <p style={{ fontWeight: 600 }}> 00</p>
        </div>
      </div>
      <div style={{ height: 600, width: "100%", marginTop: "16px" }}>
        <Box
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black !important", // Header background color
              color: "#fff", // Header text color
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when not sorted
            },
            "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when sorted
            },
            "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in descending order
              },
            "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in ascending order
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
              {
                backgroundColor: "black", // Top container row background color
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
              {
                backgroundColor: "blue", // Bottom container row background color
              },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#fafafa", // Odd row background
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#fff", // Even row background
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#e0f7fa", // Row hover color
            },
            "& .MuiDataGrid-cell": {
              color: "#000", // Cell text color
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#f5f5f5", // Footer background
            },
          }}
        >
          <DataGrid
   rowHeight={40} 
localeText={{
              noRowsLabel: "No data available.",  // Custom "No rows" message
            }}
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{
              toolbar: CustomToolbar,
            }}
            disableSelectionOnClick
            autoHeight
          />
        </Box>
      </div>
    </div>
  );
}
