import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

export const BetPrediction = ({ bet = [], open, onClose }) => {
  if (bet.length === 0) {
    // If no bets are found
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Exposure</DialogTitle>
        <DialogContent>
          <Typography align="center" variant="h6" color="textSecondary">
            No bets found
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const calculateOutcome = (outcome, bet) => {
    const { bet_amount: betAmount, bet_type: betType, fancy_Detail } = bet;
    const layBackBet =
      fancy_Detail.type === "No"
        ? fancy_Detail.layPrice1
        : fancy_Detail.backPrice1;

    let result, amount;
    const winPercentage = 0.01;

    if (betType === "Yes") {
      if (outcome < layBackBet) {
        result = "Win";
        amount = (winPercentage * betAmount * 100).toFixed(2);
      } else {
        result = "Lose";
        amount = (-betAmount).toFixed(2);
      }
    } else {
      if (outcome > layBackBet) {
        result = "Win";
        amount = (winPercentage * betAmount * 100).toFixed(2);
      } else {
        result = "Lose";
        amount = (-betAmount).toFixed(2);
      }
    }

    return { result, amount };
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Exposure
        <Typography variant="subtitle2" color="textSecondary">
          {bet[0]?.fancy_Detail?.runnerName || "N/A"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Outcome</TableCell>
              <TableCell align="center">Result</TableCell>
              <TableCell align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bet.map((singleBet, index) => {
              const { userDetails, fancy_Detail } = singleBet;
              const layBackBet =
                fancy_Detail.type === "No"
                  ? fancy_Detail.layPrice1
                  : fancy_Detail.backPrice1;
              const outcomes = [
                Number(layBackBet) - 1,
                Number(layBackBet),
                Number(layBackBet) + 1,
              ];

              return outcomes.map((outcome, outcomeIndex) => {
                const { result, amount } = calculateOutcome(outcome, singleBet);
                const usernameBgColor =
                  fancy_Detail.type === "No" ? "#add8e6" : "#f8d7da";

                return (
                  <TableRow
                    key={`${index}-${outcomeIndex}`}
                    sx={{
                      backgroundColor: result === "Win" ? "#add8e6" : "#f8d7da",
                    }}
                  >
                    {outcomeIndex === 0 && (
                      <TableCell
                        align="center"
                        rowSpan={outcomes.length}
                        sx={{
                          fontWeight: "bold",
                          backgroundColor: usernameBgColor,
                        }}
                      >
                        {userDetails?.username || "Unknown"}
                      </TableCell>
                    )}
                    <TableCell align="center">{outcome}</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: result === "Win" ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {result}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color: result === "Win" ? "green" : "red",
                        fontWeight: "bold",
                      }}
                    >
                      {amount}
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
