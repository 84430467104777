import React from 'react'

function PageLoader() {
  return (
    <div style={{display:"flex",justifyContent:"center",padding:"20px",background:'black',position:"absolute",top:0,bottom:0,right:0,left:0}} >
    <img src="/assets/softbet9-animated-logo.gif"  alt="Page Loading..." style={{scale:"0.6"}} />
</div>
  )
}

export default PageLoader