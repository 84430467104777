import React from "react";
import { Box, Container } from "@mui/material";
import SeriesListingTable from "../../components/Settings/SeriesListingTable";

const SeriesListing = () => {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <SeriesListingTable />
      </Box>
    </Container>
  );
};

export default SeriesListing;
