import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography, Tabs, Tab, useTheme, useMediaQuery } from "@mui/material";
import axiosInstance from "../../../helpers/axios";

const DwcForm = ({
  onClose,
  userDetails,
  activeTabValue,
  handleReload,
  setSnackbarMessage,
  setSnackbarOpen,
  setSnackbarSeverity,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentUser, setCurrentUser] = useState();
  const [activeTab, setActiveTab] = useState(activeTabValue);
  const [formValues, setFormValues] = useState({
    my_balance: 0,
    my_balance_after: 0,
    old_credit_limit: 0,
    new_credit_limit: 0,
    user_balance: 0,
    user_balance_after: 0,
  });
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: Yup.object({
      my_balance: Yup.number().required("Required"),
      my_balance_after: Yup.number().required("Required"),
      old_credit_limit: Yup.number().required("Required"),
      new_credit_limit: Yup.number().required("Required"),
      user_balance: Yup.number().required("Required"),
      user_balance_after: Yup.number()
        .required("Required")
        .test(
          "user_balance_after-limit-check",
          "User balance after cannot exceed old or new credit limit",
          function (value) {
            const { old_credit_limit, new_credit_limit } = this.parent;
            if (new_credit_limit > 0) {
              return new_credit_limit >= value;
            }
            return old_credit_limit >= value;
          }
        ),
      transaction_amount: Yup.number().required("Required"),
      remark: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (!activeTabValue) {
        const inputData = {
          deposited_by: currentUser._id,
          amount: values.transaction_amount,
          deposit_to: userDetails._id,
          remark: values.remark,
          newcreditlimit: values.new_credit_limit,
        };
        const response = await axiosInstance.post("/users/deposit", inputData);
        if (response.data.status) {
          setSnackbarMessage("Amount Deposited Successfully!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          handleReload();
          onClose();
        }
      } else {
        const inputData = {
          withdraw_from: userDetails._id,
          amount: values.transaction_amount,
          withdrawn_by: currentUser._id,
          newcreditlimit: values.new_credit_limit,
          remark: values.remark,
        };
        const response = await axiosInstance.post("/users/withdraw", inputData);
        if (response.data.status) {
          setSnackbarMessage("Amount Withdraw Successfully!");
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          handleReload();
          onClose();
        }
      }
    },
  });

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleGetCurrentUser = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userProfile"));
    const { data } = await axiosInstance.get(
      `/users/get-user?username=${userDetails.username}`
    );
    setCurrentUser(data.user);
  };

  useEffect(() => {
    handleGetCurrentUser();
  }, []);

  useEffect(() => {
    formik.setValues({
      my_balance: currentUser?.credits || 0,
      my_balance_after: 0,
      old_credit_limit: userDetails?.member_max_credit || 0,
      new_credit_limit: 0,
      user_balance: userDetails?.credits || 0,
      user_balance_after: 0,
    });
  }, [currentUser]);

  useEffect(() => {
    const transactionAmount = parseFloat(formik.values.transaction_amount) || 0;
    if (activeTab === 0) {
      formik.setFieldValue(
        "my_balance_after",
        formik.values.my_balance - transactionAmount
      );
      formik.setFieldValue(
        "user_balance_after",
        formik.values.user_balance + transactionAmount
      );
    } else if (activeTab === 1) {
      formik.setFieldValue(
        "my_balance_after",
        formik.values.my_balance + transactionAmount
      );
      formik.setFieldValue(
        "user_balance_after",
        formik.values.user_balance - transactionAmount
      );
    }
  }, [formik?.values?.transaction_amount]);

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        maxHeight: "60vh",
        overflowY: "auto",
        p: 2,
      }}
    >
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{ mb: 2 }}
      >
        <Tab label="Deposit" />
        <Tab label="Withdraw" />
      </Tabs>

      <Typography variant="h6" sx={{ mb: 3 }}>
        {activeTab === 0 ? "Deposit of" : "Withdraw of"} {""}
      </Typography>

      <Grid container spacing={2}>
        {/* My Balance */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="my_balance"
            name="my_balance"
            type="number"
            label="My Balance"
            variant="outlined"
            value={formik.values.my_balance}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* My Balance After */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="my_balance_after"
            name="my_balance_after"
            type="number"
            label="My Balance After"
            variant="outlined"
            value={formik.values.my_balance_after}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* Old Credit Limit */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="old_credit_limit"
            name="old_credit_limit"
            type="number"
            label="Old Credit Limit"
            variant="outlined"
            value={formik.values.old_credit_limit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.old_credit_limit &&
              Boolean(formik.errors.old_credit_limit)
            }
            helperText={
              formik.touched.old_credit_limit && formik.errors.old_credit_limit
            }
            disabled
          />
        </Grid>

        {/* New Credit Limit */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="new_credit_limit"
            name="new_credit_limit"
            type="number"
            label="New Credit Limit"
            variant="outlined"
            value={formik.values.new_credit_limit}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.new_credit_limit &&
              Boolean(formik.errors.new_credit_limit)
            }
            helperText={
              formik.touched.new_credit_limit && formik.errors.new_credit_limit
            }
          />
        </Grid>

        {/* User Balance */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="user_balance"
            name="user_balance"
            type="number"
            label="User Balance"
            variant="outlined"
            value={formik.values.user_balance}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled
          />
        </Grid>

        {/* User Balance After */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="user_balance_after"
            name="user_balance_after"
            type="number"
            label="User Balance After"
            variant="outlined"
            value={formik.values.user_balance_after}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user_balance_after &&
              Boolean(formik.errors.user_balance_after)
            }
            helperText={
              formik.touched.user_balance_after &&
              formik.errors.user_balance_after
            }
            disabled
          />
        </Grid>

        {/* Deposit or Withdraw Amount */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="transaction_amount"
            name="transaction_amount"
            type="number"
            label={activeTab === 0 ? "Deposit Amount" : "Withdraw Amount"}
            variant="outlined"
            value={formik.values.transaction_amount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>

        {/* Remark */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            id="remark"
            name="remark"
            type="text"
            label="Remark"
            variant="outlined"
            value={formik.values.remark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "flex-end",
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          sx={{ mb: 2, sm: { mr: 2 } }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{
            mb: 2,
            ml: isMobile ? 0 : 2,
            backgroundColor: "#735CFF",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#5c49cc",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default DwcForm;
