import React from "react";
import { Box, Container } from "@mui/material";

export default function Language() {
  return (
    <Container maxWidth="xl" sx={{ marginTop: "10px" }}>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            // background: "#FFFFFF",
            width: "100%",
            padding: "8px 0px 8px 16px",
            borderRadius: "10px",
            boxSizing: "border-box",
            mb: "100px",
          }}
          className="paper-box"
        >
          <p style={{ fontWeight: "Bold" }}>Change Language</p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
            >
              English
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
            >
              हिंदी
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
            >
              தமிழ்
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
            >
              मराठी
            </div>
            <div
              style={{
                backgroundColor: "black",
                borderRadius: "20px",
                color: "#FFFFFF",
                textAlign: "center",
                width: "10rem",
                margin: "0px 8px 16px 0px",
                padding: "8px 16px",
              }}
            >
              ગુજરાતી
            </div>
          </div>
        </div>
      </Box>
    </Container>
  );
}
