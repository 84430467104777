import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Checkbox from "@mui/material/Checkbox";
function BetLoackPopUP({ parameter, confirmDialog }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    if (confirmDialog.open) {
      handleClose();
    }
  }, [confirmDialog.open]);
  return (
    <>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        {parameter.row.agent_block || parameter.row.bets_block ? (
          <LockOutlinedIcon color="error" />
        ) : (
          <LockOpenOutlinedIcon color="success" />
        )}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Locks Settings
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <table>
              <tr>
                <th>Type</th>
                <th>Status</th>
              </tr>
              <tr>
                <td>User Lock</td>
                <td>
                  {/* <input
                    type="checkbox"
                    checked={parameter.row.agent_block}
                    onChange={(e) =>
                      parameter.row.handleCheckboxClick(
                        e,
                        parameter.row._id,
                        "agent_block"
                      )
                    }
                  /> */}
                  <Checkbox
                    checked={parameter.row.agent_block}
                    onChange={(e) =>
                      parameter.row.handleCheckboxClick(
                        e,
                        parameter.row._id,
                        "agent_block"
                      )
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </td>
              </tr>
              <tr>
                <td>Bet Lock</td>
                <td>
                  {/* <input
                    type="checkbox"
                    checked={parameter.row.bets_block}
                    onChange={(e) =>
                      parameter.row.handleCheckboxClick(
                        e,
                        parameter.row._id,
                        "bets_block"
                      )
                    }
                  /> */}
                  <Checkbox
                    checked={parameter.row.bets_block}
                    onChange={(e) =>
                      parameter.row.handleCheckboxClick(
                        e,
                        parameter.row._id,
                        "bets_block"
                      )
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </td>
              </tr>
            </table>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BetLoackPopUP;
