import React, { useState } from "react";
import { DataGrid, GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import { Box } from "@mui/material";

export default function CasinoReport() {
  const [value, onChange] = useState([new Date(), new Date()]);
  
function CustomToolbar() {
  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }} className="csv-download">
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem   />
        {/* <JsonExportMenuItem /> */}
      </GridToolbarExportContainer>
    </GridToolbarContainer>
  );
}
  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "sports",
      headerName: "Sports",
      flex: 1,
    },
    {
      field: "profit_loss",
      headerName: "Profit/Loss",
      flex: 1,
    },
  ];

  const data = [
    {
      id: 1,
      title: "India vs Pakistan",
      sports: "Cricket",
      profit_loss: "20",
      date: new Date().toLocaleString(),
    },
    {
      id: 2,
      title: "India vs Pakistan",
      sports: "Cricket",
      profit_loss: "20",
      date: new Date().toLocaleString(),
    },
  ];

  return (
    <div>
      <div style={{ height: 400, width: "100%" }}>
        <Box
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "black !important", // Header background color
              color: "#fff", // Header text color
            },
            "& .MuiDataGrid-columnHeader .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when not sorted
            },
            "& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-sortIcon": {
              color: "#fff", // Sort icon color when sorted
            },
            "& .MuiDataGrid-columnHeader--sortedDescending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in descending order
              },
            "& .MuiDataGrid-columnHeader--sortedAscending .MuiDataGrid-sortIcon":
              {
                color: "#fff", // Sort icon color when sorted in ascending order
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--top [role=row]":
              {
                backgroundColor: "black", // Top container row background color
              },
            "& .css-4k60b2-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]":
              {
                backgroundColor: "blue", // Bottom container row background color
              },
            "& .MuiDataGrid-row:nth-of-type(odd)": {
              backgroundColor: "#fafafa", // Odd row background
            },
            "& .MuiDataGrid-row:nth-of-type(even)": {
              backgroundColor: "#fff", // Even row background
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "#e0f7fa", // Row hover color
            },
            "& .MuiDataGrid-cell": {
              color: "#000", // Cell text color
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#f5f5f5", // Footer background
            },
          }}
        >
          <DataGrid
   rowHeight={40} 
            localeText={{
              noRowsLabel: "No data available.", // Custom "No rows" message
            }}
            rows={data}
            columns={columns}
            getRowId={(row) => row.id}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            slots={{
              toolbar: CustomToolbar,
            }}
          />
        </Box>
      </div>
    </div>
  );
}
