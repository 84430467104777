import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Collapse,
  IconButton,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axiosInstance from "../../../helpers/axios";
import Swal from "sweetalert2";

const MasterUserLimit = ({ onClose, info }) => {
  const [openMarkets, setOpenMarkets] = useState({
    cricket: false,
    tennis: false,
    soccer: false,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [formValues, setFormValues] = useState({
    profitLimit: info[0]?.profit_limit || "",
    memberMaxCredit: info[0]?.member_max_credit || "",
    sports: {
      cricket: {
        sportLimit:
          info[0]?.winningLimits?.find((s) => s.sport === "cricket")
            ?.sport_limit || "",
        bookMarket:
          info[0]?.winningLimits?.find((s) => s.sport === "cricket")
            ?.book_market_winning_limit || "",
        fancyMarket:
          info[0]?.winningLimits?.find((s) => s.sport === "cricket")
            ?.fancy_winning_limit || "",
      },
      tennis: {
        sportLimit:
          info[0]?.winningLimits?.find((s) => s.sport === "tennis")
            ?.sport_limit || "",
        bookMarket:
          info[0]?.winningLimits?.find((s) => s.sport === "tennis")
            ?.book_market_winning_limit || "",
        fancyMarket:
          info[0]?.winningLimits?.find((s) => s.sport === "tennis")
            ?.fancy_winning_limit || "",
      },
      soccer: {
        sportLimit:
          info[0]?.winningLimits?.find((s) => s.sport === "soccer")
            ?.sport_limit || "",
        bookMarket:
          info[0]?.winningLimits?.find((s) => s.sport === "soccer")
            ?.book_market_winning_limit || "",
        fancyMarket:
          info[0]?.winningLimits?.find((s) => s.sport === "soccer")
            ?.fancy_winning_limit || "",
      },
    },
  });

  const handleToggleMarket = (event) => {
    setOpenMarkets((prev) => ({
      ...prev,
      [event]: !prev[event],
    }));
  };

  const handleSportInputChange = (event, sport, field) => {
    const value = event.target.value;
    setFormValues((prev) => ({
      ...prev,
      sports: {
        ...prev.sports,
        [sport]: {
          ...prev.sports[sport],
          [field]: value,
        },
      },
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    const data = {
      user_id: info[0]._id,
      profit_limit: formValues.profitLimit,
      member_max_credit: formValues.memberMaxCredit,
      sports: Object.keys(formValues.sports).map((sport) => ({
        sport: sport,
        sport_limit: formValues.sports[sport].sportLimit,
        book_market_winning_limit: formValues.sports[sport].bookMarket,
        fancy_winning_limit: formValues.sports[sport].fancyMarket,
      })),
    };

    try {
      await axiosInstance.put("/users/update-winning-limit", data);
      Swal.fire("User Limit submitted successfully");
      onClose();
    } catch (error) {
      console.error("Error submitting data", error);
    }
  };

  return (
    <div
      style={{
        maxHeight: isMobile ? "450px" : "650px",
        overflowY: "auto",
        padding: "20px",
      }}
    >
      <h3 style={{ textAlign: "center" }}>Master User Limit</h3>
      {["Cricket", "Tennis", "Soccer"].map((event) => (
        <div key={event} style={{ marginBottom: "10px" }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                onClick={() => handleToggleMarket(event.toLowerCase())}
              >
                {openMarkets[event.toLowerCase()] ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AddCircleOutlineIcon />
                )}
              </IconButton>
            </Grid>
            <Grid item xs>
              <span>{event}</span>
            </Grid>
            <Grid item xs>
              <TextField
                size="small"
                label={`${event} Limit`}
                variant="outlined"
                margin="normal"
                fullWidth
                value={formValues.sports[event.toLowerCase()].sportLimit}
                onChange={(e) =>
                  handleSportInputChange(e, event.toLowerCase(), "sportLimit")
                }
              />
            </Grid>
          </Grid>

          <Collapse in={openMarkets[event.toLowerCase()]}>
            <div
              style={{
                paddingLeft: "20px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                fullWidth
                size="small"
                label="Book Market"
                variant="outlined"
                margin="normal"
                value={formValues.sports[event.toLowerCase()].bookMarket}
                onChange={(e) =>
                  handleSportInputChange(e, event.toLowerCase(), "bookMarket")
                }
              />
              <TextField
                fullWidth
                size="small"
                label="Fancy Market"
                variant="outlined"
                margin="normal"
                value={formValues.sports[event.toLowerCase()].fancyMarket}
                onChange={(e) =>
                  handleSportInputChange(e, event.toLowerCase(), "fancyMarket")
                }
              />
            </div>
          </Collapse>
        </div>
      ))}

      <TextField
        size="small"
        fullWidth
        label="Profit Limit"
        variant="outlined"
        margin="normal"
        name="profitLimit"
        value={formValues.profitLimit}
        onChange={handleChange}
      />
      <TextField
        size="small"
        fullWidth
        label="Member Max Credit"
        variant="outlined"
        margin="normal"
        name="memberMaxCredit"
        value={formValues.memberMaxCredit}
        onChange={handleChange}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Button
          variant="outlined"
          style={{ marginRight: "10px" }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default MasterUserLimit;
